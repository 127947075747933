/**************************Store Locator Css **********************************/

/* Storelocator.css */

.storelocator-container {
    width: 100vw;
    height: max-content;
  }
  
  .storelocator-content {
    width: 95vw;
    height: 60%;
    padding: 15px;
    // position: fixed;
    // top: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #f7f7f7;
  }
  
  .storelocator-left {
    width: 45%;
    padding: 15px;
  }
  
  .storelocator-left h1 {
    font-size: 37px;
    font-weight: 400;
    // padding: 15px 0;
    color: #000042;
  }
  
  .storelocator-left p {
    width: 90%;
    font-size: 20px;
    line-height: 2.5rem;
    font-weight: 300;
    text-align: center;
    color: #000042;
  }
  
  .storelocator-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .storelocator-button {
    padding: 15px 30px;
    font-weight: 500;
    border: 1px solid #000042;
    font-size: 20px;
    border-radius: 9999px;
    background-color: white;
    color: #000042;
  }

  .storelocator-button:hover{
    background-color: #000042;
    color: white;
  }
  
  .storelocator-right {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 30px;
  }
  
  .storelocator-item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }

  .location-name-store{
    font-weight: 600;
    color: #000042;
    font-size: 15px;
  }
  
  .storelocator-item img {
    width: 79px;
  }
  
  .storelocator-item p {
    font-weight: 600;
    text-align: center;
  }
  
  
  /************************************Store Location********************************/
  
//   #root {
//     margin: 0 auto;
//     text-align: center;
//   }
  
  
   
  .logo {
    height: 6em;
    padding: 1.5em;
    will-change: filter;
    transition: filter 300ms;
  }
  
  .logo:hover {
    filter: drop-shadow(0 0 2em #646cffaa);
  }
  
  .logo.react:hover {
    filter: drop-shadow(0 0 2em #61dafbaa);
  }
  
  @keyframes logo-spin {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
  
  @media (prefers-reduced-motion: no-preference) {
    a:nth-of-type(2) .logo {
      animation: logo-spin infinite 20s linear;
    }
  }
  
  .card {
    padding: 2em;
  }
  
  .read-the-docs {
    color: #888;
  }
  
  html,
  body {
    height: 100%;
    margin: 0;
  } 
  
  
  
  
  /* Footer Container */
  .footer-container {
    padding: 1.5rem;
    background-color: #f3f4f6; /* bg-gray-200 */
  }
  
  /* Footer Section */
  .footer-section {
    border-radius: 0.5rem;
    margin-bottom: 1.5rem;
  }

  .len2cart-logo-size{
    width: 20%;
  }
  
  /* Footer Heading */
  .footer-heading {
    font-size: 2.25rem; /* text-4xl */
    font-weight: 100; /* font-light */
    letter-spacing: 0.05em; /* tracking-wide */
    margin-top: 0;
    margin-bottom: 10px;
    color: #000042;
    text-transform: capitalize; /* capitalize */
    text-align: left;
    padding-left: 20px;
  }
  
  /* Footer List */
  .footer-list {
    display: flex !important;
    justify-content: space-between;
    flex-direction: row   !important;
    gap: 1.5rem;
  }
  
  /* Footer List Column */
  .footer-list-column {
    display: flex;
    flex-direction: column;
    gap: 1rem; /* space-y-4 */
  
    a{
      text-decoration: none;
      color: #000042;
      padding: 0 20px 0 0;
      font-size: 18px;
      font-weight: 600;
    }
  
    li{
      list-style: none;
      text-align: left;
   
    }
  }
  
  /* Footer Description Text */
  .footer-description {
    margin-bottom: 10px; 
    text-align: left;
    padding-left: 25px;
    font-size: 15px;
    color: #000042;
  }
  
  /* Footer Services */
  .footer-services {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  /* Footer App Download Text */
  .footer-app-download {
    padding-top: 2px;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #000042;
  }
  
  /* Footer App Text */
  .footer-app-text {
    text-align: center;
    margin-bottom: 1rem; /* mb-4 */
  }
  
  /* Footer App Buttons */
  .footer-app-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 10px;
  
  }
  
  /* Footer App Button */
  .footer-app-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.625rem 1rem; /* px-4 py-2.5 */
    border-radius: 0.5rem;
    background-color: #f3f4f6; /* bg-gray-800 */
    color: #1a202c;
    text-decoration: none;
   
    text-decoration: none;
    border: 2px solid #551a8b;
  
    a{
      text-decoration: none !important;
      display: flex;
    }
  }
  
   
   
  .footer-app-button svg {
    width: 1.75rem; /* w-7 */
    height: 1.75rem; /* h-7 */
    margin-right: 0.75rem;
  }
  
  /* Footer Icon (Google Play and Apple Store) */
  .footer-icon {
    fill: currentColor;
  }
  
   
  
   
  /* Footer App Text Content */
  .footer-app-text-content {
    text-align: left;
    display: flex;
    align-items: end;
    justify-content: center;
    gap: 5px;
    a{
      text-decoration: none !important;
      margin-bottom: 0px;
    }
  }
  
  .footer-app-small-text {
    font-size: 0.75rem; /* text-xs */
    margin-bottom: 0;
    text-decoration: none !important;
  }
  
  .footer-app-large-text {
    font-size: 1rem; /* text-sm */
    font-weight: 600; /* font-semibold */
    font-family: 'sans-serif';
    text-decoration: none;
    color: #736693;
    margin: auto;
  }
  
  /* Footer Bottom */
  .footer-bottom {
    width: 100%;
   
    padding: 1rem;
    text-align: center;
  
    a{
      text-decoration: none;
      color: black;
    }
  }
  
  
  
  /* styles.css */
  
  /* Container styles */
  .container2 {
    margin-top: 0;
    background-color: #fbf9f7; /* Equivalent to bg-[#fbf9f7] */
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .container2.mt-16 {
    margin-top: 4rem; /* Equivalent to mt-16 */
  }
  
  .left-content {
    width: 58%;
    padding: 10px;
  }
  
  .left-content.md\:w-full {
    width: 100%;
  }
  
  .left-content.lg\:w-65 {
    width: 65%;
  }
  
  .flex-container {
    display: flex;
    padding: 1rem;
  }
  
  .map-container2 {
    display:  block;
    width: 35%;
    height: 100vh;
    padding: 10px;
    position: sticky;
    top:90px ;
    padding: 15px;
  }
  
  /* Show the map on large screens */
  .map-container2.lg\:block {
    display: block;
  }
  
  .w-35 {
    width: 35%;
  }
  
  .h-full {
    height: 100%;
  }
  
  .header-section {
    position: relative; /* Ensure header section is positioned properly */
    z-index: 20; /* Equivalent to !z-[20] */
  }
  
  .custom-z {
    z-index: 20 !important; /* Ensures header has higher stacking context */
  }
  
  .custom-sticky {
    position: sticky;
    top: -83px; /* Equivalent to !top-[-65px] */
  }
  
  /* Search Bar */
  .search-bar {
    position: sticky;
    top: 0; /* Equivalent to top-0 */
    background-color: white; /* Equivalent to bg-white */
  }
  
  .custom-sticky-top {
    position: sticky;
    top: 0; /* Make the search bar sticky at the top */
    background-color: white; /* Apply background color for search bar */
  }
  
  
  /* General container for the header */
  .header-container1 {
    padding-left: 65px;
    padding-right: 65px;
    border-bottom: 1px solid #dbdbea;
    background-color: white ;
    margin-top:5px;
  }
  
  /* Main content wrapper inside the header */
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
  }
  
  /* Container for the logo */
  .logo-container {
    flex-shrink: 0;
    width: 70%;
  }
  
  /* Container for the contact and social icons */
  .contact-info {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    overflow-x: auto; /* Makes sure content scrolls on smaller screens */
    width: 20%;
  }
  
  /* Contact item, including the phone icon and phone number */
  .contact-item {
    display: flex;
    align-items: center;
    margin-right: 16px;
    margin-bottom: 8px;
  }
  
  /* Styling for the phone number link */
  .contact-link {
    margin-left: 8px;
    font-weight: 600;
    font-size: 15px;
    color: #000042;
  }
  
  /* Social icon container */
  .social-icon {
    margin-right: 16px;
  }
  
  .header-container {
    display: flex;
    background-color: #fbf9f7;
    position: static;
    top: 0;
    gap: 10px;
    padding: 18px;
    border-bottom: 1px solid #dbdbea;
    padding-left: 80px;
    padding-right: 80px;
    flex-direction: row;
    align-items: center;
  }
  
  .header-container.md {
    flex-direction: row;
  }
  
  .header-title-container2 {
    text-align: left;
  }
  
  .header-title-container2.md {
    text-align: left;
  }
  
  .header-title {
    margin: 0px;
    font-size: 27px;
    line-height: 36px;
    font-weight: 100;
    font-family: serif;
    color: #000042; /* gray-900 */
  }
  
  .header-subtitle {
    margin: 0px;
    font-weight: 100;
    font-size: 18px;
    font-family: normal;
    line-height: 24px;
    color: #000042;
  }
  
  .search-form {
    margin-left: 16px; /* 4 * 4px = 16px */
    width: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .search-input-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: white;
    border: 1px solid #dbdbea;
    border-radius: 12px;
    overflow: hidden;
    /* padding: 10px; */
    position: relative;
    width: 100%;
  }
  
  .search-icon {
    height: 100%;
    padding-top: 2px;
    padding-bottom: 2px;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .search-input {
    width: 100%;
    padding: 0;
    line-height: 50px;
    font-size: 17px;
    font-family: serif;
    font-weight: 400;
    border: none;
    color: #000042;
    /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); */
    outline: none;
  }
  
  .suggestions-container {
    position: absolute;
    left: 0;
    background-color: white;
    border: 1px solid #d1d5db;
    border-radius: 8px;
    overflow-y: auto;
    max-height: 160px;
    z-index: 20;
    width: 100%;
    margin-top: 4px;
  }
  
  .suggestions-container ul {
    border-top: 1px solid #e5e7eb;
  }
  
  .suggestion-item {
    cursor: pointer;
    padding: 8px 16px;
  }
  
  .suggestion-item:hover {
    background-color: #f3f4f6;
  }
  
  .suggestion-item.selected {
    background-color: #f3f4f6;
  }
  
  .location-button {
    width: auto;
    border-radius: 100px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 1px solid #a2a2b8;
    font-size: 16px;
    line-height: 24px;
    color: #000042;
    position: relative;
  }
  
  .location-button .content {
    width: 250px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .location-button .icon {
    margin-right: 11px;
  }

  .icon-location{
    position: absolute;
    left: 15px;
  }
  
  .location-button .text {
    font-weight: 700;
    color: #000042;
    font-size: 16px;
    margin-left: 10px;
  }
  
  .whatsapp-help {
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: center;
  }
  
  .whatsapp-icon {
    width: auto;
    height: 100%;
    color: #000042;
    font-weight: 700;
    line-height: 20px;
    font-size: 12px;
  }
  
  .whatsapp-help-text {
    text-decoration: underline;
    font-weight: 600;
    color: #000042;
    font-size: 14px;
  }
  
  /* Carousel Banner Container */
  .carousel-banner-container {
    outline: none;
    margin-bottom: 0.75rem; /* equivalent to mb-3 */
    max-width: 730px;
  }
  
  /* Carousel Wrapper */
  .carousel-wrapper {
    width: 100%;
    outline: none;
    max-width: 730px;
    //margin-left: auto;
    //margin-right: auto;
  }
  /* Carousel Image */
  .carousel-image {
    width: 100%;
    outline: none;
    height: 100%;
    border-radius: 1.5rem; /* equivalent to rounded-3xl */
    object-fit: cover; /* equivalent to object-cover */
  }
  
  
  .store-container {
    max-width: 730px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    background-color: var(--surface-dark);
  }
  
  .store-content {
    display: flex;
    flex-direction: row;
    padding: 20px 15px;
    border: 1px solid #dbdbea;
    border-radius: 14px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .store-image-container {
    width: 28%;
    padding: 8px;
    height: 300px;
    border-radius: 8px;
    margin-bottom: 16px;
    position: relative;
  }
  
  .store-image {
    width: 100%;
    height: 80%;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .store-details {
    width: 70%;
    margin-left: 16px;
    flex-grow: 1;
  }
  
  .store-header {
    margin-bottom: 16px;
  }
  
  .store-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .store-name {
    font-family: "LenskartSans", sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #000042;
    margin: 0px;
  }
  
  .store-name-link{
    text-decoration: none;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: -.02em;
    color: #000042;
    margin-bottom: 8px;
    cursor: pointer;
  }
  
  
  .store-rating {
    display: flex;
    align-items: center;
  }
  
  .store-rating-icon .store-rating-star {
    width: 24px;
    height: 24px;
    color: #28a745;
  }
  
  .store-rating-text {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-left: 4px;
  }
  
  .store-rating-value {
    font-weight: 500;
  }
  
  .store-reviews {
    margin-left: 4px;
  }
  
  .store-address,
  .store-hours {
    font-family: sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: -.02em;
    color: #66668e;
    text-align: left;
  }
  
  .store-contact {
    display: flex;
    align-items: center;
    margin-top: 16px;
    text-decoration:underline;
    color: #000042 !important;
  }
  
  .store-phone-icon {
    width: 24px;
    height: 24px;
  }
  
  .store-phone-number a {
    margin-left: 8px;
    font-weight: 550;
    font-family: sans-serif;
    text-decoration: underline;
    color: #000042 !important;
  }
  
  .store-buttons {
    display: flex;
    gap: 15px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  
  .store-details-button {
    border: 1px solid #000042;
    padding: 8px 15px;
    border-radius: 11px;
    background-color: #fff;
    color: #000042;
  }

  .store-details-button:hover{
    background-color: #1a202c;
    color: white;
  }
  
  .store-details-link {
    font-size: 17px;
    color: #1a202c;
    font-weight: 500;
    text-decoration: none;
  }
  
  .store-appointment-button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -.02em;
    height: 48px;
    background: #000042;
    border-radius: 12px;
    font-weight: 700;
    border: none;
    cursor: pointer;
    color: #fff;
    font-family: sans-serif;
  }
  
  .store-appointment-button .store-appointment-link {
    display: inline-flex;
    // background-color: #000042;
    color: #fff;
    padding: 12px 25px;
    border-radius: 15px;
    text-decoration: none;
    font-size: 16px;
  }
  
  .store-offers {
    display: flex;
    justify-content: center;
    gap: 10px;
    background-color: #bff6f2;
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
  }
  
//   *{
//     padding: 0px;
//     margin: 0px;
//     box-sizing: border-box;
//   }
  
  
  
  .free-reedeem{
    overflow: hidden;
    position: relative;
  }
  
  
  .free-reedeem::before{
    content: "";
    left: -50%;
    width: 30%;
    display: block;
    height: 60px;
    position: absolute;
    transform: skewX(-25deg);
    background: linear-gradient(90deg, hsla(0, 0%, 83%, 0) 0, hsla(0, 0%, 83%, .3));;
  
    animation: slidetoright 2s infinite;
  }
  
  .number-phone::-webkit-inner-spin-button{
    display: none;
  }
  
  @keyframes slidetoright{
    100%{
        left: 125%;
    }
  }
  
  
  
  /* styles.css */
  
  /* Modal Overlay (The full-screen background) */
  .modal-overlay2 {
    position: fixed;
    top: 0;
    left: 0;
   width: 100%; /* Equivalent to w-screen */
    height: 100%; /* Equivalent to h-screen */
    z-index: 30; /* Equivalent to z-[30] */
    display: flex;
    justify-content: center; /* Equivalent to justify-center */
    align-items: center; /* Equivalent to items-center */
  }
  
  /* Modal Content (The actual modal box) */
  .modal-content {
    position: fixed;
    top: 50%; /* Center the modal vertically */
    left: 50%; /* Center the modal horizontally */
    transform: translate(-50%, -50%); /* Adjust the modal to be perfectly centered */
    width: 40%; /* Equivalent to w-[40%] */
    max-height: 70%; /* Equivalent to max-h-[70%] */
    z-index: 31; /* Equivalent to z-[31] */
    background-color: white; /* Equivalent to bg-white */
    border-radius: 20px; /* Equivalent to rounded-[20px] */
    padding: 15px; /* Equivalent to py-[15px] */
    min-height: 300px; /* Equivalent to min-h-[300px] */
    overflow: hidden;
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /* ---------------------Chat GPT------------------------ */
  /* Add the CSS rules for the component */
  
  .contact-details {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    border-bottom: 1px solid #66668e;
    padding-top: 0px;
    padding-bottom: 0px;
    flex-direction: column;
  }
  
  .contact-title {
    width: 100%;
    font-size: 24px;
    color: #66668e;
    font-family: 'serif';
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: start;
    margin: 0px;
    line-height: 36px;
    font-weight: 400;
    position: relative;
  
  }
  
  .close-btn {
    position: absolute;
    right: 28px;
    padding-left: 0px;
    padding-right: 0px;
    background-color: transparent;
    border: none;
  }
  
  
  .close-icon {
    color: #66668e;
  }
  
  .contact-description {
    width: 100%;
    text-align: left;
    margin: 0px;
    font-size: 14px;
    font-weight: 400;
    color: #66668e;
    font-family: 'serif';
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }
  
  
  /* -------------------form---------------- */
  
  /* General form styles */
  .contact-form {
    // width: 100%;
    overflow: hidden;
    height: auto;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 23px;
    padding-bottom: 23px;
  }
  
  /* Form Group styles */
  .form-group {
    width: 100%;
    border: 1px solid #a2a2b8;
    margin-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
  }
  
  /* Label styles */
  .form-label {
    width: auto;
    font-size: 15px;
    font-family: 'serif';
    color: #66668e;
    font-weight: 400;
  }
  
  /* Input field styles */
  .phone-input, .name-input {
    height: 30px;
    color: #66668e;
    outline: none;
    border-radius: 8px;
    width: 100%;
    outline: none;
    border: none !important;
  }
  
  .phone-input::-webkit-inner-spin-button{
    display: none;
  }
  
  /* Free redeem section styles */
  .free-redeem {
    width: 120%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #bff6f2;
  }
  
  /* Redeem text styles */
  .redeem-text {
    padding: 0;
    font-size: 12px;
    font-weight: 500;
    color: #000042;
    line-height: 20px;
  }
  
  /* Form submit button styles */
  .form-submit {
    width: 100%;
    padding-top: 22px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Submit button styles */
  .submit-button {
    width: 100%;
    color: #66668e;
    height: auto;
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-weight: 700;
    font-size: 18px;
    background-color: #dbdbea;
    border: none;
  }
  
  
  
  /* Modal overlay styles */
  .modal-overlay {
    position: fixed;
    z-index: 30;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    opacity: 0.4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  
  .ad-container {
    max-width: 730px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  /* Ad Card */
  .ad-card {
    border-radius: 16px;
    width: 100%;
  }
  
  /* Ad Image */
  .ad-image {
    width: 100%;
    height: 285px;
    object-fit: cover;
    border-radius: 24px;
  }
  
  
  /* Container styles */
  .hours-container {
    min-width: 150px;
    max-width: fit-content;
    background-color: #fafafe;
    // display: flex;
    // flex-direction: column;
    // align-items: flex-start;
    // justify-content: center;
    height: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 8px;
    padding-left: 10px;
    padding-right: 10px;
    color: #000042;
  }
  
  /* Text styles */
  .hours-text {
    font-size: 14px;
    font-weight: 400;
  }
  
  
  
  
  /* ------------header bottom----------- */
  /* Container for the whole section */
  .section-container {
    width: 80%;
    min-height: 200px;
    padding-top: 35px;
    padding-bottom: 60px;
    max-height: max-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 70px;
    padding-right: 70px;
  }
  
  /* Container for image */
  .image-container {
    width: 30%;
    height: 370px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Image styling */
  .image-container img {
    width: 100%;
    height: 85%;
    border-radius: 0.375rem; /* rounded-md */
    object-fit: cover;
    background-position: center;
    background-size: cover;
  }
  
  /* Main content container */
  .content-container {
    height: 333px;
    width: 67%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 40px;
  }
  
  /* Title Styling */
  .title-store-locator {
    font-size: 40px;
    font-weight: 400;
    color: #000042;
    font-family: 'serif';
    letter-spacing: -0.1rem;
    margin-bottom: 0px;
  }
  
  /* Address Styling */
  .address {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #333368;
  }
  
  /* Container for the boxes (possibly buttons or other elements) */
  .box-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    margin-bottom: 20px !important;
  }
  
  /* Container for the additional section */
  .additional-section {
    width: 100%;
    gap: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  /* The banner that contains the offer */
  .offer-banner {
    width: 100%;
    background-color: #bff6f2;
    display: flex;
    font-size: 14px;
    font-weight: 600 !important;
    align-items: center;
    justify-content: center;
    color: #000042;
    text-align: center;
    font-family: Lenskart Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: -.28px;
  }
  
  .offer-banner p {
    font-size: 14px;
    font-weight: 450;
  }
  
  /* Button container */
  .button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  /* Common button styles */
  .button {
    display: inline-flex;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 9px;
    padding-bottom: 9px;
    font-size: 1rem; /* text-lg */
    font-weight: 500; /* font-medium */
    border-radius: 10px;
  }
  
  /* Specific styles for each button */
  .book-button {
    background-color: #000042;
    color: white;
    overflow: hidden;
    padding: 13px;
    width: 25%;
  
  }
  
  .direction-button,
  .call-button,
  .help-button {
    border: 1px solid #000042;
    color: #000042;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 16px;
    font-weight: 500;
    background-color: transparent !important;
    border-radius: 10px;
    display: flex ;
    align-items: center;
    justify-content: start;
    gap: 10px;
    padding: 13px;
    width: 23%;
    span{
        display: flex;
        align-items: center;
        justify-content: center;
    }
  }
  
  
  
  /* -----------------last---------------- */
  
  .lastmsain{
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
    gap: 20px;
  }
  
  .container-store-home {
    width: 60%;
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    padding-right: 15px;
    overflow-x: scroll;
    height: auto;
  }


  .container-product-card{
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 15px;
      padding-right: 15px;
      height: auto;
  }
  
  .reviews-section {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    height: auto;
  }
  
  .reviews-title {
  
    font-family: "Lenskart Serif";
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    margin-top: 0px;
    margin-bottom: 20px;
    color: #000042;
    /* Styles for the h1 tag in the reviews section */
  }
  
  .reviews-list {
    display: flex;
    align-items: center;
    gap: 10px;
    overflow-x: scroll;
    justify-content: flex-start;
  }
  
  .review-card {
    width: 300px;
    border-radius: 8px;
    background-color: #fafafe;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 25px !important;
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .review-text {
    font-size: 12px;
    font-weight: 450;
    line-height: 24px;
    color: #000042;
    text-align: left;
  }
  
  .review-name {
    width: 100%;
    font-size: 15px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    height: auto;
  }
  
  .review-image {
    width: 25px;
    height: auto;
  }
  
  .about-store {
    width: 100%;
    height: auto;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  
  .about-store-title {
    font-size: 24px;
    font-weight: 400;
    color: #000042;
    line-height: 36px;
    margin-bottom: 20px;
    font-family: "Lenskart Serif";
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    margin-top: 35px;
    margin-bottom: 20px;
    color: #000042;
  }
  
  .about-store-text {
    font-family: Lenskart Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: -.02em;
    color: #333368;
    text-align: left;
  }
  
  .nearby-stores {
    width: 100%;
    height: auto;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  
  // .nearby-stores-title {
  // }
  
  .store-list {
    width: 100%;
    height: auto;
    overflow-x: scroll;
  }
  
  .store-item {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  
  
  .store-list{
    display:flex;
  
    justify-content:start  ;
    width: 100%;
  }
  
  
  .store-item{
    font-family: Lenskart Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    letter-spacing: -.02em;
    color: #66668e;
    padding: 20px;
    min-width: 300px;
    flex-direction: column;
    align-items: start;
    background: #fff;
    border: 1px solid #cecedf;
    box-shadow: 0 12px 16px -4px rgba(16, 24, 40, .08), 0 4px 6px -2px rgba(16, 24, 40, .03);
    border-radius: 8px;
    margin-bottom: 16px;
    margin-right: 16px;
    justify-content: start;
    transition: .3s;
  }
  
  .store-item-name{
    font-family: Lenskart Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: -.02em;
    color: #000042;
    margin-bottom: 8px;
    cursor: pointer;
  }
  
  
  .para-store-item{
    text-align: left;
  }
  
  .nearby-stores-title{
    font-family: "Lenskart Serif";
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    margin-top: 35px;
    margin-bottom: 20px;
    color: #000042;
    text-align: left;
  }
  
  .map-container {
    width: 30%;
    min-height: 500px;
    max-height: fit-content;
    position: sticky;
    top: 0;
  }
  
  .map-container iframe {
    border: none;
  }
  
  
  .reviews-list {
    width: 100%; /* or specify a fixed width */
    overflow-x: auto; /* enable horizontal scrolling */
    white-space: nowrap; /* prevent reviews from wrapping to the next line */
    overflow: scroll;
  }
  
  .review-card {
    display: inline-block; /* allow cards to be side by side */
    width: auto; /* adjust width to fit content */
    margin-right: 10px; /* space between reviews */
  }
  
  .review-text {
    max-width: 300px; /* or a fixed width to control text area */
   // white-space: normal; /* allow text to wrap within the card */
    overflow: hidden; /* hide overflow text */
    text-overflow: ellipsis; 
    font-size: 14px;
  }
  
  .antifog-spray{
  
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 42px 35px;
    background: #f7f2ed;
    border-radius: 12px;
    margin-top: 20px !important;
  
    h1{
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: #000042;
    }
  
    .para{
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #66668e;
        margin-top: 8px;
        flex-direction: column;
    }
  }
  
  
  .storehomepage{
     width: 100%;
 box-sizing: border-box;
    height: fit-content;
    padding-bottom: 40px;
  }


/********************************************************/

.productcard-main{
  width: 300px;
  min-height: 200px;
  max-height: max-content;
  display: flex;
  align-items: center;
  justify-content: start;
  border: 1px solid #e2e2ee;
  border-radius: 1rem;
  box-shadow: #0000001f 0px 0px 6px;
  flex-direction: column;
  padding: 10px;
  padding-bottom: 10px;
}

.product-image-container{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-name{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  height: auto;
  line-height: 1px;

  h2{
      font-size: 15px;
      font-family: sans-serif;
      color: #000042;
  }

  div{
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
 
      p{
          font-size: 13px;
          font-weight: 400;
          font-family: sans-serif;
          padding-right: 10px;
          color: #000042;
      }
  }
}


.product-hr{
  width: 92%;
  margin-bottom: 15px;
  background: transparent;
  border: 1px dotted #ccc;
}


.productprice-btn{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

.product-price-store{
  display: flex;
  width: 100%;
  height: 30px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
}

.product-price-store-span{
  width: 100px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 17px;
  font-family: sans-serif;
  font-weight: 500;
  color: #000042;
  span{
      width: auto;
      height: 17px;
      img{
          width: 100%;
          height: 100%;
          background-position: center;
          object-fit: cover;
      }
  }
      
  
}

.product-price-store-reserve{
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000042;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 18px;
  padding-bottom: 18px;
  border: none;
  font-size: 15px;
  font-style: sans-serif;
  font-weight: 500;
  border-radius: 10px;
  cursor: pointer;

}



.tab-content{
  width: 60%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction:  column;
}

.tab-button{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid black; */
  height: 20px;
  margin-bottom: 20px;
}


.tab-single-button{
  width: 48%;
  height: 100%;
  border: none;
  border-bottom: 1px solid #000042;
  display: flex ;
  align-items: center;
  justify-content: center;
  padding-bottom: 17px;
  padding-top: 17px;
  font-family: Lenskart Sans;
  font-style: normal;
  font-size: 18px;
  color: #000042;
  background-color: transparent;
  
}

.active1{
  font-weight: 550;
  border-bottom: 2px solid #000042;
}


/* -------------------------product page------------------------ */
.container{
  overflow-x: auto;
}

.searchbox{
  width: 95%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: start;
  background-color: white;
  border: 1px solid #cecedf;
  border-radius: 15px;
  overflow: hidden;
  margin: auto;

}

.searchbox-span{
  width: 7%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  color: #cecedf;
}

.searchbox-input{
  width: 93%;
  height: 100%;
  border: none;
  color: #212529;
  font-size: 16px;
 
  padding-top: 10px;
  padding-bottom: 10px;
  outline: none;
  letter-spacing: .4px;
}


.storehome-all-product{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  
  flex-wrap: wrap;
  padding:15px;

}


.opennow-label {
  position: absolute;
  top: 15px;
  background-color: #bff6f2;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  right: 15px;
  border-radius: 1rem;
  font-size: 15px;
  font-weight: 500;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  margin: 0 2px;
  letter-spacing: -.02em;
  color: #000042;
}


.mobile-book {
  display: none;
}