.kidglasses-main{
    width: 100%;
    height: 100%;
    display: flex;
    align-items:  start;
    justify-content: center;
    /* border: 1px solid red; */
    padding-left: 20px;
    padding-right: 20px;
    
}


.kidglasses-subdiv{
    width: 100%;
    min-height: 385px;
    display: flex;
    align-items:  start;
    padding-top: 40px;
    justify-content: center;
    gap: 60px;
    /* border: 1px solid black; */
}

.single-div-for-man{
    margin-left: 20px;
    margin-right: 20px;
    padding: 35px 25px;
    width: 200px;
    height: 200px;
    border-radius: 0.5rem;
    background-color: #fbfbfb;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.img-single-div{
    width: 75%;
    height: 150px;
}

.p-img-single-div{
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    margin-top: 0px;
    color: #000042;
}

 



/* Responsive design for mobile screens */
@media screen and (max-width: 640px) {
    .kidglasses-main {
        padding-left: 10px;
        padding-right: 10px;
        height: auto;
    }

    .kidglasses-subdiv {
        gap: 20px; /* Reduce gap for smaller screens */
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;
    }

    .single-div-for-man {
        padding: 15px; /* Reduce padding for smaller screens */
       
    }

    .p-img-single-div {
        font-size: 14px; /* Smaller font size */
    }
}

 