
.main-div-left-appointment {
  width: 500px;
  min-height: 800px;
  max-height: fit-content;
  /* border: 1.4px solid black; */
  display: flex;
  align-items: center;
  gap: 30px;
  flex-direction: column;
}

.videoplayer-appointment {
  width: 400px;
  height: auto;
  border-radius: 30px;
}

.div-for-6sign-appointment {
  width: 400px;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* overflow: hidden; */
  background-color: rgb(247, 242, 237);
  border-radius: 20px;
}

.h2-for-sign-appointment {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;

  gap: 7px;
  icon {
    width: 30px;
    height: auto;
    font-size: 3rem;
  }
  p {
    font-size: 1.3rem;
    font-family: serif;
    width: 80%;
    display: flex;
    flex-wrap: wrap;
  }
}

.div-for-all-sign-appointment {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* overflow: hidden; */
  gap: 25px;
  /* animation-name: animation;
    animation-iteration-count: infinite;
    animation-duration: 4s; */
  padding: 5px;
}

@keyframes animation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.div-for-all-sign-appointment::-webkit-scrollbar {
  display: none;
}

.div-inside-sign-appointment {
  width: 100px;
  height: 90px;

  background-color: white;
  font-size: 15px;
  font-family: serif;
}

.slick-slider-appointment {
  width: 100%;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-option-section-appointment {
  width: 100%;
  height: auto;
  padding-left: 30px;
  /* padding-right: 30px; */
  cursor: pointer;
}

.div-for-para-appointment {
  display: none;
  cursor: pointer;
}

.show-appointment {
  display: block;
}

.div-inside-appointment {
  width: 180px;
  height: 100%;
  background-color: white;
  color: black;
}

.slider-appointment {
  width: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
}

.p-slider-appointment {
  width: 40px;
  border: 1px solid black;
  background: transparent;
  color: black;
  z-index: 20;
}

.h2-for-option-appointment {
  font-style: normal;
  font-weight: var(--fw-bold);
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 36px;
  color: var(--dark-blue-100);
  font-family: var(--font-plus-jakarta-sans-bold) !important;
}

.h3-for-select-appointment {
  width: calc(100% - 20px);
  display: flex;
  text-align: initial;
  padding-top: 10px;
  padding-bottom: 10px;

  -webkit-box-align: center;
  align-items: center;
  justify-content: space-between;
  font-family: serif;
  font-style: normal;
  font-weight: 560;
  font-size: 15px;
  line-height: 20px;
  color: var(--dark-blue-100);
  cursor: pointer;
}

.rotate-icon-appointment {
  transform: rotate(180deg);
  transition: all 200ms;
}

.div-for-select-section-appointment {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1.3px solid rgb(226, 226, 238);
}

.need-for-help-appointment {
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.need-p-appointment {
  font-size: 15px;
  font-weight: 550;
  width: auto;
  height: auto;
}

.a-help-appointment {
  text-decoration: none;
  cursor: pointer;
}

.review-box-appointment {
  width: 100%;
  background-color: white;
  padding-left: 20px;
  padding-right: 20px;
  gap: 20px;
  min-height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.h3-for-r-box-appointment {
  width: 100%;
  font-size: 1.5rem;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.comment-box-appointment {
  background-color: rgb(247, 242, 237);
  display: flex;
  padding: 15px 20px;
  flex-direction: column;
  /* border: 1px solid red; */
  border-radius: 15px;
  width: 90%;
  /* gap: 20px; */
}

.comment-top-appointment {
  min-height: 40%;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.p-name-appointment {
  width: auto;
  font-size: 20px;
  margin-left: 13px;
  display: inline;
}

.appointment-span {
  width: auto;
  height: auto;
  padding: 10px;
  border-radius: 50%;
  font-size: 1.7rem;
  background-color: white;
}
