@import "../css/Variable.scss";

@media screen and (min-width: 1600px) {
  .navbar-top {
    height: 65px !important;
    font-size: 25px;
    z-index: 5;
  }

  .input {
    height: 25px !important;
    font-size: 17px !important;
    width: 400px !important;
  }

  .nav-bottom-option {
    font-size: 18px !important;
  }

  .navbar-top-cart {
    font-size: 22px !important;
    cursor: pointer;
  }

  .navbar-top-sign {
    font-size: 22px !important;
    cursor: pointer;
  }

  .navbar-top-help {
    font-size: 22px !important;
    cursor: pointer;
  }

  .navbar-link-icon {
    font-size: 19px !important;
  }

  .bold600 {
    font-size: 18px !important;
  }

  .nav-main-option {
    font-size: 17px !important;
  }

  .nav-icon-heading {
    font-size: 17px !important;
  }

  .poductcard4-head {
    font-size: 17px !important;
  }

  .poductcard4-txt {
    font-size: 15px !important;
  }

  .navbar-bottom {
    height: 50px !important;
  }

  .nav-space {
    height: 115px !important;
  }

  .card {
    width: 100%;
    height: 200px !important;
  }
  .Category-banner-img {
    height: 350px;
  }
}
