/* *{
    padding: 0px;
    margin: 0px;
} */

.input-field-appointment {
  height: 30px;
  outline: none;
  border: 1px solid rgb(230, 230, 236);
  padding: 10px;
  margin-left: 6px;
}

.input-field-appointment::-webkit-input-placeholder {
  color: #000042;
}
