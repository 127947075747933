.component-delete-web-template {
  display: none;
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 5px;
  color: rgb(255, 18, 18);
  background-color: #00000000;
  cursor: pointer;
  position: absolute;
  margin: 0;
  padding: 0;
  z-index: 4;
}

.display-none-web-template {
  display: none !important;
}

#two-column-one {
  z-index: 1;
}

.colomn-one-1-web-template {
  width: 100%;
  height: 100%;
  padding: 0px;
}

.add-item-icon-web-template {
  font-size: 16px;
  color: rgb(119, 119, 119);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* border: 1px dotted rgb(24, 24, 24); */
  padding: 6px;
  border-radius: 8px;
}

.fa-trash-web-template {
  font-size: 10px;
}

.drop-target-web-template {
  width: 100%;
  min-height: 95vh;
  height: max-content;
  background-color: rgb(255, 255, 255);
  padding-bottom: 150px;
  padding-top: 0.1px-web-template;
}

.drop-target1-web-template {
  width: 100%;
  min-height: 70vh;
  height: max-content;
  background-color: rgb(255, 255, 255);
  z-index: 5;
}

.column-one-web-template {
  width: 98%;
  min-height: 37vh;
  height: max-content;
  border: 1px dashed rgb(0, 0, 0);
  margin: auto;
  margin-top: 0px;
  background-color: rgb(253, 253, 253);
  padding: 0px;
  margin-top: 8px;
  margin-bottom: 8px;
  min-width: max-content;
}

.two-column-one-web-template {
  width: 98%;
  min-height: 7vh;
  height: max-content;
  /* border: 1px solid rgb(0, 0, 0); */
  margin: auto;
  margin-top: 0px;
  display: flex;
  justify-content: space-evenly;
  /* align-items: center; */
  margin-bottom: 0px;
  flex-direction: row;
}

.Two-column-mobile-web-template {
  flex-direction: column;
}

.Two-column-tablet-web-template {
  flex-direction: row;
}

.Three-column-mobile-web-template {
  flex-direction: column;
}

.three-and-seven-web-template {
  width: 98%;
  min-height: 7vh;
  height: max-content;
  margin: auto;
  margin-top: 0px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 10px;
}

.two-column-1-web-template {
  width: 100%;
  min-width: max-content;
  min-height: 7vh;
  height: max-content;
  /* border: 1px dashed rgb(0, 0, 0); */
  /* margin: 0px 2px; */
  margin: 7px auto;
}

.two-column-2-web-template {
  width: 100%;
  min-height: 7vh;
  height: max-content;
  min-width: max-content;
  /* margin-left: 0px; */
  margin: 0px 3px;
  margin-top: 5px;

  /* border: 1px dashed rgb(0, 0, 0); */
}

.three-column-web-template {
  width: 99%;
  min-height: 7vh;
  height: max-content;
  /* border: 1px solid rgb(0, 0, 0); */
  margin: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-evenly;
  /* align-items: center; */
}

.three-column-1-web-template {
  width: 100%;
  min-height: 8vh;
  height: max-content;
  min-width: max-content;
  /* border: 1px dashed rgb(0, 0, 0); */
  /* margin: auto; */
  margin: 0px 5px;
  margin-top: 5px;
}

.draggable-text-web-template {
  width: 100%;
  height: max-content;
  margin: 0 auto;
  margin-top: 10px;
}

.draggable-txt-input-web-template {
  width: 100%;
  height: 25px;
  padding-left: 10px;
  font-weight: 600;
  /* font-size: 18px; */
  /* border: none; */
  border: 1px solid rgb(0, 0, 0);
}

.draggable-link-web-template {
  text-decoration: none;
  color: blue;
  font-size: 15px;
  font-weight: 600;
}

/* #column-one-delete {
  display: none;
}
#column-two-delete {
  display: none;
}
#three-column-delete {
  display: none;
}
#countdown-delete {
  display: none;
}
#form-delete {
  display: none;
}
#textarea-delete {
  display: none;
}
#tabs-delete {
  display: none;
}
#image-delete{
  display: none;
} */

.column-one-delete-web-template {
  display: block !important;
  position: relative;
  bottom: -15px;
  left: 4px;
  z-index: 5;
  position: absolute;
}

.input-box-form-web-template {
  width: 95%;
  display: flex;
  justify-content: space-between;
  margin: auto;
  margin-top: 6px;
  margin-bottom: 6px;
}

.label-form-web-template {
  font-size: 15px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.label-input-small-web-template {
  width: 60%;
  box-shadow: 0px 0px 5px rgb(199, 199, 199);
  border: none;
  height: 25px;
  border-radius: 8px;
  padding-left: 5px;
  font-size: 15px;
}

.input-box-form-main-web-template {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
}

.form-button-web-template {
  width: 100px;
  float: right;
  margin-top: 20px;
  bottom: 20px;
  height: 30px;
  border: 0px;
  right: 0;
  background-color: rgb(51, 109, 216);
  color: white;
  border-radius: 12px;
  font-size: 15px;
  margin-right: 2%;
}

.form-button-main-web-template {
  width: max-content;
  min-width: 100px;
  border: 0;
  background-color: rgb(51, 109, 216);
  color: white;
  border-radius: 12px;
  font-size: 15px;
}

.button-name-web-template {
  width: max-content;
  height: 35px;
  background-color: rgba(255, 255, 255, 0);
  border: 0;
  text-align: center;
  color: white;
  font-weight: 600;
  /* font-size: 16px; */
}

.button-name-web-template::placeholder {
  color: white;
  font-weight: 600;
  font-size: 14px;
}

.label-input-small-main-web-template {
  width: 200px;
  box-shadow: 0px 0px 5px rgb(199, 199, 199);
  border: none;
  height: 35px;
  border-radius: 8px;
  padding-left: 5px;
  font-size: 15px;
}

.textarea-main-web-template {
  width: 98%;
  margin: 12px;
  padding: 5px;
}

/* ******************TABS************************ */

.tab-btn-box-web-template {
  display: flex;
  padding: 10px 8px 10px 8px;
}

.tab-content-box-web-template {
  display: flex;
}

.tab-btn-1-web-template {
  padding: 3px 10px;
  margin: 8px;
  border: 1px dotted black;
}

.tab-content-11-web-template {
  width: 98%;
  height: 30vh;
  margin-top: 0;
  padding: 8px 10px;
  border: 1px solid #e1e1e1;
  margin: 10px auto;

  box-shadow: 1px 1px 3px rgb(182, 182, 182);
}

.tab-content-2-web-template {
  width: 98%;
  height: max-content;
  margin-top: 0;
  padding: 8px 10px;
  border: 1px solid #e1e1e1;
  margin: auto;
  box-shadow: 1px 1px 3px rgb(182, 182, 182);
}

.tab-content-3-web-template {
  width: 98%;
  height: 30vh;
  margin-top: 0;
  padding: 8px 10px;
  border: 1px solid #e1e1e1;
  margin: auto;
  box-shadow: 1px 1px 3px rgb(182, 182, 182);
}

/*  slider image */

.simple-slider-web-template {
  width: 100%;
  margin: auto;
  height: 100%;
}

.active-web-template {
  transform: translateX(0);
}

/* .slider-image-web-template{
  width: 100%;
  height: 60vh;
  max-height: 97vh;
} */

.slider-button-right-web-template {
  position: absolute;
  right: 8px;
  top: 45%;
  background: transparent;
  font-size: 30px;
  color: white;
  border-radius: 50px;
}

.slider-button-left-web-template {
  position: absolute;
  top: 45%;
  background: transparent;
  font-size: 25px;
  left: 8px;
  color: white;
  border-radius: 50px;
}

.slider-button-right-web-template {
  background: transparent;
  color: #fff;
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 600;
  border: none;
  padding: 5px 10px;
  perspective: 30rem;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.308-web-template);
}

.slider-button-right-web-template::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.262-web-template);
  z-index: 1;
  transition: background 3s;
}

.image-slider-container-web-template {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 80vh;
  position: relative;
  max-width: 100%;
}

.slider-button-right-web-template:hover::before {
  animation: rotate 0.5s-web-template;
  transition: all 0.5s-web-template;
}

@keyframes rotate {
  0% {
    transform: rotateY(180deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}

.slider-mobile-web-template {
  height: 33vh;
}

.slider-tablet-web-template {
  height: 55vh;
}

.slider-button-left-web-template {
  background: transparent;
  color: #fff;
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 600;
  border: none;
  padding: 5px 10px;
  perspective: 30rem;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.308-web-template);
}

.slider-button-left-web-template::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* right: 8px; */
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.262-web-template);
  z-index: 1;
  transition: background 3s;
  scale: 1;
}

.slider-button-left-web-template:hover::before {
  animation: rotate 0.5s-web-template;
  transition: all 0.5s-web-template;
}

@keyframes rotate {
  0% {
    transform: rotateY(180deg);
    /* scale: 1; */
  }

  100% {
    transform: rotateY(360deg);
    /* scale: 1.3-web-template; */
  }
}

/* // **********************column-one-content***************************? */

.column-one-content-web-template {
  width: 100%;
  /* z-index: 5; */
  min-height: 100px;
  /* height: max-content; */
  outline: 1px dotted black;
}

/* COUNTDOWN */

.countdown-box-web-template {
  display: flex;
  /* border: 1px solid black;    */
  justify-content: center;
}

.countdown-box-small-web-template {
  display: flex;
  border: 1px solid rgb(155, 155, 155);
  padding: 5px 0px;
  font-size: 20px;
  font-weight: 600;
  margin: 6px;
  width: 90px;
  color: rgb(80, 80, 80);
  justify-content: center;
  box-shadow: 0px 3px 3px rgb(124, 124, 124);
  border-radius: 12px;
}

.countdownwidth-mobile-web-template {
  width: 60px;
  font-size: 14px;
}

.countdownwidth-tablet-web-template {
  width: 80px;
  font-size: 16px;
}

/*  **********************  Resizeable Content  *************************** */

.resizable-web-template {
  background: white;
  width: 100%;
  /* height: 40vh; */
  /* position: sticky;   */
  max-width: 100%;
  height: max-content;
  padding-bottom: 0px;
  /* margin: auto; */
}

.resizers-web-template {
  width: 100%;
  height: 100%;
  /* border: 1px dotted #1a1c1f; */
  box-sizing: border-box;
  position: relative;
  background-size: 100% 100%;
}

.resizable-web-template .resizers-web-template .resizer-web-template {
  width: 8px;
  height: 8px;
  background: white;
  border: 2px solid rgb(0, 127, 231);
  position: absolute;
  z-index: 1;
  border-radius: 0px;
  display: none;
}

.resizer-web-template.top-web-template {
  left: 50%;
  /* top: -px;c */
  cursor: ns-resize;
  transform: translate(-7px, 0px);
  display: none !important;
}

.resizer-web-template.right-web-template {
  right: -3px;
  bottom: 50%;
  cursor: ew-resize;
  transform: translate(0px, 5px);
}

.resizer-web-template.bottom-web-template {
  /* display: none !important; */
  left: 50%;
  bottom: -4px;
  cursor: ns-resize;
  transform: translate(-7px, 0px);
}

.resizer-web-template.left-web-template {
  left: -3px;
  bottom: 50%;
  cursor: ew-resize;
  transform: translate(0px, 5px);
}

.three-and-seven-1-web-template {
  width: 74%;
  min-height: 7vh;
  min-width: max-content;
  height: max-content;
  margin-right: 5px;
}

.three-and-seven-2-web-template {
  width: 24%;
  min-height: 7vh;
  min-width: max-content;
  height: max-content;
  margin-right: 5px;
}

.resizers1-web-template {
  width: 100%;
  height: 100%;
  border: 1px dotted #1a1c1f;
  box-sizing: border-box;
}

.resizable1-web-template .resizers1-web-template .resizer1-web-template {
  width: 8px;
  height: 8px;

  background: white;
  border: 2px solid rgb(0, 127, 231);
  position: absolute;
  border-radius: 3px;
  display: none;
}

.resizer-web-template.top1-web-template {
  left: 50%;
  top: -5px;
  cursor: ns-resize;
  transform: translate(-7px, 0px);
}

.resizer-web-template.right1-web-template {
  right: -5px;
  bottom: 50%;
  cursor: ew-resize;
  transform: translate(0px, 5px);
}

.resizer-web-template.bottom1-web-template {
  left: 50%;
  bottom: -5px;
  cursor: ns-resize;
  transform: translate(-7px, 0px);
}

.resizer-web-template.left1-web-template {
  left: -5px;
  bottom: 50%;
  cursor: ew-resize;
  transform: translate(0px, 5px);
}

/* image modal */

.draggable-image-web-template {
  width: 100%;
  height: 30vh;
}

/* ************************toggle box css************************** */

.togglebox-web-template {
  width: 100%;
  height: max-content;
}

.togglebox-1-web-template {
  width: 99%;
  height: 35px;
  margin: auto;
  margin-bottom: 3px;
  border-radius: 5px;
  background-color: rgb(214, 214, 214);
  display: flex;
  align-items: center;
  padding-left: 15px;
  justify-content: space-between;
  margin-top: 0px;
}

/* ?????????????INPUT BOX???????????? */

.toggle-text-web-template {
  font-size: 15px;
  font-weight: 600;
}

.toggle-details-web-template {
  width: 99%;
  height: 115px;
  margin: auto;
  margin-bottom: 8px;
  border-radius: 5px;
  background-color: rgb(236, 236, 236);
  animation: toggleopen 500ms ease-out;
}

@keyframes toggleopen {
  0% {
    height: 0;
    opacity: 0;
    /* Optional: fade-in animation as well */
  }

  100% {
    height: 115px;
    opacity: 1;
    /* Optional: fade-in animation as well */
  }
}

#arrow1toggle {
  font-size: 18px !important;
}

#arrowtoggle {
  font-size: 18px !important;
}

.rotateicon-toggle-web-template {
  transform: rotate(90deg);
  transition: all;
  transition-duration: 500ms;
}

/* *********************  CALENDER CSS****************************** */

.calendar-web-template {
  width: 97%;
  margin: 0 auto;
  text-align: center;
}

.calendar-header-web-template {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.calendar-day-web-template {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  background-color: #f7f7f7;
  margin: 1px;
  font-size: 16px;
  border-radius: 5px;
}

.calendar-row-web-template {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.calendar-header-web-template button {
  border: none;
  padding: 8px 10px;
  border-radius: 8px;
}

.calendar-header-web-template h2 {
  font-size: 15px;
}

/* /////////////////////ALERT BOX///////////////////////////// */

.alert-box-web-template {
  width: 99%;
  min-height: 75px;
  height: max-content;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 22px;
  margin: auto;
  border-radius: 5px;
  background-color: #d9edf7;
  margin-top: 8px;
  margin-bottom: 8px;
}

.alert-box-heading-web-template {
  font-size: 17px;
  margin: 0;
  line-height: 30px;
  /* color: #0e628b; */
}

.alert-box-paragraph-web-template {
  /* font-size: 13px; */
  margin: 0;
  /* color: #155577; */
}

/* /////////////icon-box/////////////////////////////////////// */

.icon-icon-box-web-template {
  font-size: 27px;
  padding: 5px;
  text-align: center;
  border: 1px solid rgb(218, 215, 215);
  margin: 10px;
  border-radius: 15px;
  background-color: #f3f3f3;
  width: 40px;
  transition: all;
  box-shadow: 0px 3px 3px rgb(124, 124, 124);
  transition-duration: 500ms;
  font-weight: 300;
  cursor: pointer;
}

.icon-icon-box-web-template:hover {
  transform: scale(1.2) rotateY(360deg);
  box-shadow: 0px 8px 10px rgb(124, 124, 124);
}

.icon-icon-box-web-template:active {
  box-shadow: none;
  transition: none;
}

.icon-icon-main-box-web-template {
  display: flex;
  justify-content: center;
}

.fa-facebook:hover {
  color: #0f8ff2;
}

.fa-instagram:hover {
  color: #ff002d;
}

.fa-linkedin:hover {
  color: #0073b2;
}

.fa-whatsapp:hover {
  color: #34b34b;
}

.fa-twitter:hover {
  color: #1da1f2;
}

/* ************ search--box******************************************** */

.search-input-box-web-template {
  width: 70%;
  max-width: 300px;
  margin-right: 2px;
  margin-left: 5px;
  padding: 7px;
  border: 1px solid #000000;
  border-radius: 0.5em-web-template;
}

.search-button-web-template {
  border: 2px solid #24b4fb;
  background-color: #24b4fb;
  border-radius: 0.7em-web-template;
  /* padding: 5px 10px; */
  color: rgb(255, 255, 255);
  transition: all ease-in-out 0.2s-web-template;
  font-size: 16px;
  width: 60px;
}

.search-button-web-template .search-button-span-web-template {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: 600;
}

.search-button-web-template:hover {
  background-color: #0071e2;
}

.iconlibrary-modal-web-template {
  width: 900px;
  height: 550px;
  border: 1px solid rgba(94, 94, 94, 0.822-web-template);
  /* position: absolute; */
  /* transform: translate(95%, 5%); */
  color: rgb(0, 0, 0);
  background-color: rgb(56, 56, 56);
  /* border-radius: 8px; */
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: modalslide 700ms;
}

@keyframes modalslide {
  0% {
    transform: translate(-50%, -80%);
  }

  100% {
    transform: translate(-50%, -50%);
  }
}

.iconmodal-left-web-template {
  width: 20%;
  background-color: white;
  height: 100%;
  /* border-radius: 5px; */
  padding: 10px;
  border: 1px solid black;
  border-top: none;
  border-right: none;
}

.iconmodal-pgr-web-template {
  display: flex;
  justify-content: center;
  font-size: medium;
  background-color: rgba(47, 170, 170, 0.61-web-template);
  font-weight: 600;
  padding: 15px;
  width: 100%;
  margin: 4px;
  border-radius: 5px;
}

.iconsearch-bar-web-template {
  border-radius: 4px;

  display: flex;
  margin-top: 1vh;
  width: 100%;
  justify-content: center;
}

.iconinput-web-template {
  border: none;
  outline: none;
  width: 60%;
  border-radius: 5px;
  border: 1px solid black;
  padding: 7px;
}

.iconmodal-right-web-template {
  width: 80%;
  background-color: whitesmoke;
  border: 1px solid black;
  border-top: none;
}

.search-icon-web-template {
  margin-left: -3%;
  margin-top: 8px;
}

.icon-section-web-template {
  display: flex;
  margin-top: 2%;
  overflow-y: scroll;
  height: 88%;
}

.iconmodal-header-web-template {
  height: 8vh;
  background-color: white;
  border: 1px solid black;
  display: flex;
  justify-content: space-between;
  padding: 13px 20px;
}

.leftright-flex-web-template {
  display: flex;
  height: 100%;
}

.icon-para-web-template {
  font-size: medium;
  font-weight: 600;
}

.large-red-web-template {
  font-size: x-large;
  color: red;
  cursor: pointer;
}

.m-aut-web-template {
  margin: 5vh auto;
  height: 30vh !important;
  width: 90%;
  border: 2px dashed rgb(206, 206, 206);
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  margin-bottom: 15px !important;
  background-color: rgb(87, 87, 87);
  box-shadow: 3px 3px 8px black;
}

.iconlib-btn-web-template {
  display: flex;
  justify-content: center;
  margin-top: -5vh;
}

.iconlib-btn-web-template {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 45px;
  height: 45px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition-duration: 0.3s-web-template;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199-web-template);
  background-color: rgb(87 87 87);
  margin: auto;
}

/* plus sign */
.iconlib-sign-web-template {
  width: 100%;
  transition-duration: 0.3s-web-template;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconlib-sign-web-template svg {
  width: 17px;
}

.iconlib-sign-web-template svg path {
  fill: white;
}

/* text */
.iconlib-text-web-template {
  position: absolute;
  right: 0%;
  width: 0%;
  opacity: 0;
  color: white;
  font-size: 1.2em-web-template;
  font-weight: 600;
  transition-duration: 0.3s-web-template;
}

/* hover effect on button width */
.iconlib-btn-web-template:hover {
  width: 125px;
  border-radius: 40px;
  transition-duration: 0.3s-web-template;
}

.iconlib-btn-web-template:hover .iconlib-sign-web-template {
  width: 30%;
  transition-duration: 0.3s-web-template;
  padding-left: 20px;
}

/* hover effect button's text */
.iconlib-btn-web-template:hover .iconlib-text-web-template {
  opacity: 1;
  width: 70%;
  transition-duration: 0.3s-web-template;
  padding-right: 10px;
}

/* button click effect*/
.iconlib-btn-web-template:active {
  transform: translate(2px, 2px);
}

.card-title-web-template {
  color: #262626;
  font-size: 16px;
  line-height: normal;
  font-weight: 700;
  margin-bottom: 0;
}

.small-desc-web-template {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5em-web-template;
  color: #452c2c;
}

.small-desc-web-template {
  font-size: 1em;
}

.go-corner-web-template {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 2em;
  height: 2em;
  overflow: hidden;
  top: 0;
  right: 0;
  background: linear-gradient(135deg, #6293c8, #384c6c);
  border-radius: 0 4px 0 32px;
}

.go-arrow-web-template {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}

.card-web-template {
  display: block;
  position: relative;
  max-width: 300px;
  max-height: 320px;
  background-color: #f2f8f9;
  border-radius: 10px;
  padding: 15px 20px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  background: linear-gradient(to bottom, #c3e6ec, #a7d1d9);
  font-family: Arial, Helvetica, sans-serif;
}

.card-web-template:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: linear-gradient(135deg, #47617e, #49628a);
  /* background-color: rgb(128, 128, 128); */
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.35s-web-template ease-out;
}

.card-web-template:hover:before {
  transform: scale(30);
}

.card-web-template:hover .small-desc-web-template {
  /* transition: all 0.5s-web-template ease-out; */
  color: rgba(255, 255, 255, 0.8-web-template);
}

.card-web-template:hover .card-title-web-template {
  /* transition: all 0.5s-web-template ease-out; */
  color: #ffffff;
}

/* *********************navbar-types********************************** */

.navbar-types-web-template {
  width: 100%;
  height: 45px;
  background: #00699d;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-menu-web-template {
  width: 100px;
  display: flex;
  color: white;
  font-size: 16px;
  justify-content: center;
  height: 50px;
  align-items: center;
  transition: all;
  transition-duration: 400ms;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  /* border-left: 1px solid white; */
}

.navbar-menu-web-template:hover {
  /* font-size: 17px;
  opacity: 0.8-web-template;
  text-shadow: 2px 2px 2px rgb(255, 255, 255); */
  color: rgb(0, 0, 0);
}

.navbar-menu-box-web-template {
  display: flex;
}

.navbar-logo-web-template {
  width: 80px;
  height: 75%;
  margin-left: 15px;
}

.navbar-logo-img-web-template {
  width: 100%;
  height: 100%;
  /* border-radius: 8px; */
}

.navbar-menu-web-template.active-web-template {
  color: rgb(255, 255, 255);
  text-shadow: 3px 3px 5px rgb(0, 0, 0);
  font-size: 17px;
  background: #f9f2ff57;
}

.nav-mobile-web-template {
  display: flex !important;
}

.nav-mobile-screen-web-template {
  background-color: #00699d;
  display: flex;
  height: 50px;
  justify-content: space-between;
  display: none;
}

.nav-bars-box-web-template {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-bars-web-template {
  font-size: 20px;
  color: white;
}

.nav-bars-web-template:hover {
  font-size: 20px;
  color: rgb(0, 0, 0);
  cursor: pointer;
}

.navbar-mbile-logo-img-web-template {
  width: 100%;
  height: 80%;
  border-radius: 0px;
}

.nav-bars-box-img-web-template {
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
}

/* ***********************footer********************** */

.footer-website-content-web-template {
  /* position: absolute; */
  width: 100%;
  min-height: 100px;
  background-color: #00699d;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.footer-content-1-web-template {
  color: white;
  width: 20%;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.footer-content-2-web-template {
  color: white;
  width: 20%;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.footer-content-3-web-template {
  color: white;
  width: 20%;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.footer-content-4-web-template {
  color: white;
  width: 20%;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.tabs-web-template {
  display: flex;
}

.tab-web-template {
  padding: 5px 15px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  cursor: pointer;
  margin-right: 10px;
}

.tab-web-template.active-web-template {
  background-color: #275280;
  color: #fff;
  border: none;
  box-shadow: none;
  transition: all;
  transition-duration: 500ms;
  box-shadow: 4px 4px 6px rgb(175, 175, 175);
}

/* ??????????DELETE MODAL????????????? */

.dlt-modal-web-template {
  width: 300px;
  height: 130px;
  /* border: 1px solid rgba(255, 255, 255, 0.822-web-template); */
  color: rgb(0, 0, 0);
  background-color: rgb(241, 241, 241);
  border-radius: 8px;
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -150%);
  animation: deleteoption 700ms;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 5px;
  box-shadow: 0px 0px 10px rgba(46, 46, 46, 0.719-web-template);
}

@keyframes deleteoption {
  0% {
    transform: translate(-50%, -210%);
  }

  100% {
    transform: translate(-50%, -150%);
  }
}

.dlt-button-box-web-template {
  display: flex;
  justify-content: space-around;
  width: 85%;
}

.dlt-modal-text-web-template {
  font-size: 16px;
  width: 80%;
  text-align: center;
  font-weight: 600;
}

.dlt-modal-delete-btn-web-template {
  --width: 100px;
  --height: 35px;
  --tooltip-height: 35px;
  --tooltip-width: 90px;
  --gap-between-tooltip-to-button: 18px;
  --button-color: #1163ff;
  --tooltip-color: #fff;
  width: var(--width);
  height: var(--height);
  background: var(--button-color);
  position: relative;
  text-align: center;
  border-radius: 0.45em-web-template;
  font-family: "Arial";
  transition: background 0.3s-web-template;
}

.text-web-template {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dlt-modal-delete-btn-wrapper-web-template,
.text-web-template {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  color: #fff;
}

.dlt-modal-delete-btn-web-template:hover {
  background: #0f44a5;
  cursor: pointer;
}

.dlt-modal-cancel-btn-web-template {
  --width: 100px;
  --height: 35px;
  --tooltip-height: 35px;
  --tooltip-width: 90px;
  --gap-between-tooltip-to-button: 18px;
  --button-color: #f01c00;
  --tooltip-color: #fff;
  width: var(--width);
  height: var(--height);
  background: var(--button-color);
  position: relative;
  text-align: center;
  border-radius: 0.45em-web-template;
  font-family: "Arial";
  transition: background 0.3s-web-template;
}

.text-web-template {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dlt-modal-cancel-btn-wrapper-web-template,
.text-web-template {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  color: #fff;
}

.dlt-modal-cancel-btn-web-template:hover {
  background: #a81c0a;
  cursor: pointer;
}

.dlt-modal-icon-web-template {
  color: white;
  font-size: 14px;
  margin-left: 5px;
}

/* ***************************************************** */

.map-size-web-template {
  /* height: 50vh; */
}

.leaflet-container-web-template {
  height: 100% !important;
}

.map-mobile-web-template {
  height: 25vh;
}

.map-tablet-web-template {
  height: 40vh;
}

.video-iframe-web-template {
  height: 315px;
}

.editimagebutton-web-template {
  opacity: 0;
}

.hover-border-web-template {
  outline: 1px solid rgb(61, 176, 253);
}

.component-web-template {
  position: relative;
}

.hovered-component-name-web-template {
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.7-web-template);
  color: white;
  padding: 10px;
  border-radius: 5px;
}

/* *****************************Media query************************************ */

@media (max-width: 1200px) {
  .video-iframe-web-template {
    height: 225px;
  }
}

@media (max-width: 900px) {
  .video-iframe-web-template {
    height: 150px;
  }

  .conditional-modal-web-template {
    width: 80%;
    height: 650px;
  }
}

@media (max-width: 650px) {
  .draggable-heading {
    font-size: 15px !important;
  }
  .link-web-template {
    font-size: 13px !important;
  }

  .two-column-one-web-template {
    flex-direction: column;
  }

  .c-modal-input-box-web-template {
    display: flex;
    width: 90%;
    margin: auto;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }

  .c-modal-input-small-web-template {
    width: 90%;
    margin-top: 15px;
  }

  .c-modal-input-small2-web-template {
    width: 90%;
    margin-top: 15px;
  }

  .c-modal-input-small3-web-template {
    width: 90%;
    margin-top: 15px;
  }

  .three-column-web-template {
    flex-direction: column;
  }

  .three-column-1-web-template {
    width: 98%;
    margin-top: 0;
    margin-bottom: 5px;
  }

  .map-size-web-template {
    height: 30vh;
    width: 95%;
    margin: auto;
  }

  .tab-content-11-web-template {
    width: 94%;
    margin: 10px auto;
  }

  .tab-content-2-web-template {
    width: 94%;
    margin: 10px auto;
  }

  .tab-content-3-web-template {
    width: 94%;
    margin: 10px auto;
  }

  .tab-btn-box-web-template {
    display: flex;
    padding: 20px 12px 5px 12px;
  }

  .image-slider-container-web-template {
    height: 30vh;
  }

  .togglebox-1-web-template {
    width: 95%;
    margin-top: 5px;
  }

  .toggle-details-web-template {
    width: 95%;
    margin-top: 5px;
  }

  .alert-box-web-template {
    width: 95%;
  }

  .calendar-web-template {
    width: 95%;
  }

  .calendar-day-web-template {
    height: 40px;
  }

  .input-box-form-main-web-template {
    padding: 20px 10px;
  }

  /* *********NAVBAR******************* */

  .navbar-types-web-template {
    display: none !important;
  }

  .nav-mobile-screen-web-template {
    display: flex !important;
  }

  .sidebar-mobile-web-template {
    display: flex !important;
  }

  .navbar-menu-web-template.active-web-template {
    color: rgb(255, 255, 255);
    text-shadow: 3px 3px 5px rgb(0, 0, 0);
    font-size: 17px;
    background: #47474757 !important;
  }
}
