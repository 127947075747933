@import "../css/Variable.scss";

body {
  margin: 0;
  padding: 0;
  font-family: $primary-font;
}

body {
  background-color: $background-color-light;
  color: $text-color-light;
}

.nav-space {
  width: 100%;
  height: 98px;
  background-color: #f7f7f7;
}

.navbar {
  position: fixed;
  width: 100%;
  z-index: 5;
  top: 0;
  background-color: $navbar-background-color;
  // background-color: rgb(238, 8, 8);
  box-shadow: 0px 0px 10px rgb(175, 175, 175);
}

.navbar-top {
  color: $navbar-text-color;
  border-bottom: $border-light;
  display: flex;
  justify-content: space-between;
  height: 55px;
  align-items: center;
  padding: 0px 20px;
  z-index: 5;
  // background: linear-gradient(to right, white , rgba(185, 250, 255, 0.753) , rgb(255, 255, 255));
}

.navbar-top-links {
  width: 25%;
  display: flex;
  justify-content: space-around;
}

.navbar-top-logo {
  width: 10%;
  display: flex;
  justify-content: space-around;
  color: $navbar-text-color;
}

.navbar-top-search {
  width: 60%;
  display: flex;
  justify-content: space-around;
}

.navbar-top-help {
  font-size: 15px;
  color: black;
  cursor: pointer;
}

.navbar-top-sign {
  font-size: 15px;
  cursor: pointer;
  color: black;
}

.navbar-top-cart {
  font-size: 15px;
  cursor: pointer;
  color: black;
}

.navbar-top-cart:hover {
  scale: 1.2;
}

.navbar-top-help:hover {
  scale: 1.2;
}

.navbar-top-sign:hover {
  scale: 1.2;
}

.navbar-link-icon {
  color: $navbar-text-color;
  font-size: 12px;
}

// navbav bottom

.navbar-bottom {
  color: $navbar-text-color;
  outline: $border-light;
  margin-bottom: 1px;
  display: flex;
  justify-content: space-evenly;
  height: 35px;
  align-items: center;
  padding: 0px 40px;
  z-index: 50;
}

.navbar-bottom-2 {
  color: $text-color-light;
  border-bottom: $border-light;
  display: flex;
  justify-content: space-evenly;
  height: 45px;
  align-items: center;
  padding: 0px 40px;
}

//   search panel navbar

.input {
  width: 380px;
  height: 15px;
  padding: 8px 15px;
  border-radius: 8px;
  border: 1px solid rgb(211, 211, 211);
  outline: none;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  box-shadow: 0px 0px 20px -18px;
  font-family: $primary-font;
  font-size: 13px;
  color: black;
}

.input:hover {
  border: 2px solid lightgrey;
  box-shadow: 0px 0px 20px -17px;
}

.input::placeholder {
  color: black;
}

.input:active {
  transform: scale(0.95);
}

.input:focus {
  border: 2px solid grey;
}

.nav-bottom-option {
  font-size: 13px;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  cursor: pointer;
}

.nav-bottom-option:hover {
  background-color: $Theme-color;
  color: white;
}

/* this is a recreation of twitter search in css */
.form {
  --input-text-color: #fff;
  --input-bg-color: #ffffff;
  --focus-input-bg-color: transparent;
  --text-color: #949faa;
  --active-color: #ffffff;
  --width-of-input: 200px;
  --inline-padding-of-input: 1.2em;
  --gap: 0.9rem;
}

/* form style */
.form {
  font-size: 0.9rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: 400px;
  position: relative;
  isolation: isolate;
}

/* a fancy bg for showing background and border when focus. */
.fancy-bg {
  position: absolute;
  width: 100%;
  inset: 0;
  background: var(--input-bg-color);
  border-radius: 30px;
  height: 100%;
  z-index: -1;
  pointer-events: none;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

/* label styling */
label {
  width: 100%;
  padding: 0.8em;
  height: 10px;
  padding-inline: var(--inline-padding-of-input);
  display: flex;
  align-items: center;
}

.search,
.close-btn {
  position: absolute;
}

/* styling search-icon */
.search {
  fill: var(--text-color);
  left: var(--inline-padding-of-input);
}

/* svg -- size */
svg {
  // width: 17px;
  display: block;
}

/* styling of close button */
.close-btn {
  border: none;
  right: var(--inline-padding-of-input);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #949faa;
  padding: 0.1em;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ffffff;
  opacity: 0;
  visibility: hidden;
}

/* styling of input */
.input1 {
  color: $text-color-light;
  width: 100%;
  margin-inline: min(2em, calc(var(--inline-padding-of-input) + var(--gap)));
  background: none;
  border: none;
}

.input1:focus {
  outline: none;
}

.input1::placeholder {
  color: var(--text-color);
}

/* input background change in focus */
.input1:focus ~ .fancy-bg {
  border: 1px solid var(--active-color);
  background: var(--focus-input-bg-color);
}

/* search icon color change in focus */
.input1:focus ~ .search {
  fill: black;
}

/* showing close button when typing */
.input1:valid ~ .close-btn {
  opacity: 1;
  visibility: visible;
}

/* this is for the default background in input,when selecting autofill options -- you can remove this code if you do not want to override the browser style.  */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}

.nav-bottom-option-2 {
  font-size: 15px;
  color: #3b3b3b;
  cursor: pointer;
}

// underline css

.underline-css {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  text-decoration: none;
  font-weight: 400;
  color: $text-color-light;
  transition: all 0.35s ease;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: $text-color-light;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
  }

  &:hover {
    color: rgb(0, 0, 0);

    &::before {
      visibility: visible;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
  }
}

.navbar-top-links-2 {
  width: 10%;
  display: flex;
  justify-content: space-around;
}

.navbar-link-icon-2 {
  color: $text-color-light;
  font-size: 15px;
}

.navbottom-onhover {
  width: 100%;
  height: 350px;
  background-color: $background-color-light;
  background-color: #e7e7e73b;
  position: absolute;
  z-index: 5;
}

.hovertrue-background {
  background-color: rgb(187, 187, 187);
  /* or any color you want */
}

.hoverfalse-background {
  background-color: white;
  /* or any other color */
}

.nav-bar-main {
  width: 80%;
  height: 270px;
  background-color: $background-color-light;
  outline: $border-light;
  margin: auto;
  display: flex;
}

.nav-bar-main-parts {
  width: 25%;
  border-left: $border-light;
}

.nav-bar-1-category {
  // border-bottom: $border-light;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 77px;
  align-items: center;
  justify-content: center;
  transition: all;
  transition-duration: 200ms;
  width: 80%;
  margin: auto;
  cursor: pointer;
}

.nav-bar-1-category:hover {
  background-color: $Theme-color;
  font-weight: 100;
  color: rgb(255 255 255);
  width: 80%;
  margin: auto;
  border-radius: 10px;
}

.nav-icon-image {
  width: 30px;
}

.nav-icon-heading {
  font-size: 13px;
}

.bold600 {
  font-weight: 600;
  font-size: 12px;
  color: $text-color-light;
  width: 100%;
  text-align: center;
  margin-top: 5px;
  padding-bottom: 10px;
}

.nav-main-option-box {
  width: 80%;
  margin: auto;
  margin-top: 0px;
  display: grid;
  grid-template-columns: repeat(1, auto);
  cursor: pointer;
}

.nav-main-option {
  line-height: 28px;
  // width: 100%;
  text-align: center;
  // padding-left: 20px;
  font-size: 12px;
  color: $text-color-light;
  margin: 0px 10px;
}

.nav-main-option:hover {
  background-color: $Theme-color;
  // font-weight: 600;
  border-radius: 8px;
  color: white;
}

.dark-theme {
  background-color: $background-color-dark;
  color: $text-color-dark;

  .navbar {
    color: $text-color-dark;
  }

  .navbar-link-icon {
    color: $text-color-dark;
    font-size: 13px;
  }

  .navbar-top-help {
    color: $text-color-dark;
  }

  .navbar-top-sign {
    color: $text-color-dark;
  }

  .navbar-top-cart {
    color: $text-color-dark;
  }

  .navbar-top-logo {
    color: $text-color-dark;
  }

  .input::placeholder {
    color: rgb(250, 250, 250);
  }

  .input {
    background-color: rgb(34, 34, 34);
    color: white;
    border: $background-color-dark;
  }

  .nav-bottom-option {
    // font-size: 13px;
    color: $text-color-dark;
  }

  .nav-bottom-option-2 {
    // font-size: 13px;
    color: $text-color-dark;
  }

  .underline-css {
    position: relative;
    text-decoration: none;
    font-weight: 400;
    color: $text-color-dark;
    transition: all 0.35s ease;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: $text-color-dark;
      visibility: hidden;
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transition: all 0.3s ease-in-out 0s;
      transition: all 0.2s ease-in-out 0s;
    }

    &:hover {
      color: $text-color-dark;

      &::before {
        visibility: visible;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
      }
    }
  }

  .nav-bottom-option:hover {
    background-color: rgb(123 123 123);
  }

  .navbottom-onhover {
    background-color: #3d3d3da1;
  }

  .navbar-link-icon-2 {
    color: $text-color-dark;
  }

  .input1:focus {
    outline: none;
    color: $text-color-dark;
  }

  .input1:focus ~ .search {
    fill: var(--active-color);
  }

  // .image-slider-button {
  //   // color: $text-color-dark;
  // }
  .nav-bar-main {
    background-color: $background-color-dark;
  }

  .navbottom-onhover {
    width: 100%;
    height: 350px;
    // background-color:  $background-color-dark;
    // background-color: #0000003f;
    position: absolute;
    z-index: 1;
  }

  .hovertrue-background {
    background-color: rgb(187, 187, 187);
    /* or any color you want */
  }

  .hoverfalse-background {
    background-color: white;
    /* or any other color */
  }

  .nav-bar-main {
    width: 90%;
    height: 270px;
    // background-color: $background-color-dark;
    background-color: rgba(0, 0, 0, 0.856);
    margin: auto;
    display: flex;
  }

  .nav-bar-main-parts {
    width: 25%;
    border-left: $border-light;
    border-bottom: $border-light;
    border-right: $border-light;
  }

  // .nav-bar-main-parts-1{
  //   width: 20%;
  //   border-left: $border-light;
  //   border-bottom: $border-light;
  //   border-right: $border-light;
  // }

  .nav-bar-1-category {
    // border-bottom: $border-light;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 77px;
    align-items: center;
    justify-content: center;
  }

  .nav-bar-1-category:hover {
    background-color: rgb(73, 73, 73);
    color: white;
    font-weight: 100;
  }

  .nav-icon-image {
    width: 30px;
  }

  .nav-icon-heading {
    font-size: 13px;
    // border-bottom: $border-light;
  }

  .bold600 {
    color: $text-color-dark;

    border-bottom: $border-light;
  }

  .nav-main-option-box {
    margin-top: 0px;
  }

  .nav-main-option {
    color: $text-color-dark;
  }

  .nav-main-option:hover {
    background-color: rgb(73, 73, 73);
    font-weight: 100;
  }
}

// darktheam togglebuttom css

/* Default */
.theme {
  display: none;
  // display: flex;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  position: fixed;
  bottom: 5px;
  z-index: 10;
  justify-content: flex-end;
}

.theme__fill,
.theme__icon {
  transition: 0.3s;
}

.theme__fill {
  background-color: var(--bg);
  display: block;
  mix-blend-mode: difference;
  position: fixed;
  inset: 0;
  height: 100%;
  transform: translateX(-100%);
}

.theme__icon,
.theme__toggle {
  z-index: 1;
}

.theme__icon,
.theme__icon-part {
  position: absolute;
}

.theme__icon {
  display: block;
  top: 0.5em;
  left: 0.5em;
  width: 1.5em;
  height: 1.5em;
}

.theme__icon-part {
  border-radius: 50%;
  box-shadow: 0.4em -0.4em 0 0.5em hsl(0, 0%, 100%) inset;
  top: calc(50% - 0.4em);
  left: calc(50% - 0.5em);
  width: 15px;
  height: 15px;
  transition: box-shadow var(--transDur) ease-in-out,
    opacity var(--transDur) ease-in-out, transform var(--transDur) ease-in-out;
  transform: scale(0.5);
}

.theme__icon-part ~ .theme__icon-part {
  background-color: hsl(0, 0%, 100%);
  border-radius: 0.05em;
  top: 50%;
  left: calc(50% - 0.05em);
  transform: rotate(0deg) translateY(0.5em);
  transform-origin: 50% 0;
  width: 0.1em;
  height: 0.2em;
}

.theme__icon-part:nth-child(3) {
  transform: rotate(45deg) translateY(05px);
}

.theme__icon-part:nth-child(4) {
  transform: rotate(90deg) translateY(05px);
}

.theme__icon-part:nth-child(5) {
  transform: rotate(135deg) translateY(05px);
}

.theme__icon-part:nth-child(6) {
  transform: rotate(180deg) translateY(05px);
}

.theme__icon-part:nth-child(7) {
  transform: rotate(225deg) translateY(05px);
}

.theme__icon-part:nth-child(8) {
  transform: rotate(270deg) translateY(5px);
}

.theme__icon-part:nth-child(9) {
  transform: rotate(315deg) translateY(5px);
}

.theme__label,
.theme__toggle,
.theme__toggle-wrap {
  position: relative;
}

.theme__toggle,
.theme__toggle:before {
  display: block;
}

.theme__toggle {
  border: 1px solid rgb(100, 100, 100);
  background-color: hsl(48, 90%, 85%);
  border-radius: 25% / 50%;
  // box-shadow: 0 0 0 0.125em var(--primaryT);
  padding: 0.25em;
  width: 4em;
  height: 2em;
  -webkit-appearance: none;
  appearance: none;
  transition: background-color var(--transDur) ease-in-out,
    box-shadow 0.15s ease-in-out, transform var(--transDur) ease-in-out;
}

.theme__toggle:before {
  background-color: hsl(48, 90%, 55%);
  border-radius: 50%;
  content: "";
  width: 1.5em;
  height: 1.5em;
  transition: 0.3s;
}

.theme__toggle:focus {
  box-shadow: 0 0 0 0.125em var(--primary);
  outline: transparent;
}

/* Checked */
.theme__toggle:checked {
  background-color: hsl(198, 90%, 15%);
}

.theme__toggle:checked:before,
.theme__toggle:checked ~ .theme__icon {
  transform: translateX(2em);
}

.theme__toggle:checked:before {
  background-color: hsl(198, 90%, 55%);
}

// .theme__toggle:checked ~ .theme__fill {
//   transform: translateX(0);
// }

.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(1) {
  box-shadow: 0.2em -0.2em 0 0.2em hsl(0, 0%, 100%) inset;
  transform: scale(1);
  top: 0;
  left: -0.2em;
}

.theme__toggle:checked ~ .theme__icon .theme__icon-part ~ .theme__icon-part {
  opacity: 0;
}

.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(2) {
  transform: rotate(45deg) translateY(0.8em);
}

.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(3) {
  transform: rotate(90deg) translateY(0.8em);
}

.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(4) {
  transform: rotate(135deg) translateY(0.8em);
}

.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(5) {
  transform: rotate(180deg) translateY(0.8em);
}

.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(6) {
  transform: rotate(225deg) translateY(0.8em);
}

.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(7) {
  transform: rotate(270deg) translateY(0.8em);
}

.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(8) {
  transform: rotate(315deg) translateY(0.8em);
}

.theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(9) {
  transform: rotate(360deg) translateY(0.8em);
}

.theme__toggle-wrap {
  margin: 0 0.75em;
}

@supports selector(:focus-visible) {
  .theme__toggle:focus {
    box-shadow: 0 0 0 0.125em var(--primaryT);
  }

  .theme__toggle:focus-visible {
    box-shadow: 0 0 0 0.125em var(--primary);
  }
}

.nav-bar-mobile {
  display: none;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 60px;
  border-bottom: $border-light;
  align-items: center;
  justify-content: space-between;
  background-color: $Theme-color;
  background-color: white;
}

.mobile-nav-icon {
  font-size: 20px;
  margin-right: 11px;
  margin-left: 11px;
  color: $Mobile-icon-color;
  color: black;
}

/*pushing a hamburger menu into a pillow texture like background*/

.menuButton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 13%;
  color: #090909;
  width: 50px;
  height: 35px;
  border-radius: 0.5em;
  transition: all 0.3s;
}

.menuButton span {
  width: 20px;
  height: 2px;
  background: $Mobile-icon-color;
  background-color: black;
  border-radius: 100px;
  transition: 0.3s ease;
}

input[type]:checked ~ span.top {
  transform: translateY(6px) rotate(45deg);
  width: 25px;
}

input[type]:checked ~ span.bot {
  transform: translateY(-7px) rotate(-45deg);
  width: 25px;
}

input[type]:checked ~ span.mid {
  transform: translateX(-20px);
  opacity: 0;
}

.sidebarback {
  width: 100%;
  height: 150vh;
  background-color: rgba(0, 0, 0, 0.301);
  //  display: none;
  position: fixed;
  z-index: 11;
}

.sidebar-1 {
  position: fixed;
  height: 93vh;
  width: 310px;
  background-color: $sidebar-background;
  z-index: 7;
  border-right: $border-light;
  transition: transform 700ms ease;
  transform: translateX(350px);
  right: 0;
  overflow: scroll;
}

.sidebar-1.show {
  transform: translateX(0);
}

.sidebar-1-logo {
  display: none !important;
  padding-top: 5px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  border-bottom: $border-light;
}

.sidebar-1-button-box {
  display: flex;
  flex-direction: column;
}

.sidebar-1-btn {
  height: 35px;
  display: flex;
  font-size: 15px;
  align-items: center;
  justify-content: flex-start;
  width: 87%;
  margin: auto;
  color: $sidebar-font-color;
  padding: 5px 20px;
  border-bottom: 1px solid rgb(235, 235, 235);
}

.sidebar-1-icon {
  width: 24px;
  margin-right: 15px;
}

.logo-sidebar-1 {
  width: 70px;
}

// **************************Lenscart*****************************

.navbar_theme_3 {
  position: fixed;
  top: 0;
  width: 100%;
  background: white;
  z-index: 100;
}

.navbar-top-theme_3 {
  height: 30px;
  display: flex;
  justify-content: flex-start;
  font-size: 11px;
  padding: 7px 30px;
  align-items: center;
  word-spacing: 0.1px;
}

.navabar_middle_theme_3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  margin: 1px 0px 0px;
  height: 50px;
}

.navbar-top-theme_3 p {
  padding: 0 6px;
  border-right: 1px solid $Theme_3color;
  color: $Theme_3color;
}

// .navbar-middle-theme_3 {}

.navbar_bottom_theme_3 {
  display: flex;
  justify-content: space-between;
  background-color: rgb(251, 249, 247);
  border-bottom: 1px solid lightgrey;
}

.navbar_Bottom_left_theme_3 {
  width: 70%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: var(--justify);
  justify-content: space-between;
  height: 55px;
  padding: 0 25px;
  position: relative;
  background-color: rgb(251, 249, 247);
}

.navbar_Bottom_left_theme_3 div {
  height: 95%;
  font-family: $font-Theme3;
  color: $Theme_3color;
  line-height: 60px;
  font-size: 16px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  padding: 0px;
  cursor: pointer;
  padding: 0px 9px 0px 11px;
  border-bottom: 3px solid rgb(251, 249, 247);
  white-space: nowrap;
  font-weight: 600;
  letter-spacing: -0.5px;
}

.navbar_Bottom_left_theme_3 div:hover {
  border-bottom: 3px solid $Theme_3color;
}

.navbar_Bottom_right_theme_3 {
  width: 30%;
}

.Logo_theme_3_box {
  width: 51%;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 550px) {
  .Logo_theme_3_box {
    width: 45%;
  }
}

.logo_theme_3 {
  height: 57px;
}

.mobileNo_Theme_lc {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  color: $Theme_3color;
  margin-top: -3px;
}

.mobileNo_Theme_lc p {
  margin: 0;
  margin-left: 6px;
}

.search_box_theme_3 {
  background: rgb(255, 255, 255);
  width: 100%;
  padding: 4px 7px;
  margin: 0px;
  position: relative;
  box-sizing: border-box;
  border: 1px solid $Theme_3color;
  border-radius: 5px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: $Theme_3color;
  outline: none;
  margin-top: -8px;
  user-select: initial;
  font-size: 15px;
  font-weight: 100;
  // margin-left: 10px;
}

.Theme_3_navbar_Buttons {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 30.5%;
  font-weight: 500;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 13px;
  color: $Theme_3color;
  margin-top: -10px;
}

.navabar_middle_theme_3-logo-box {
  display: flex;
  width: 25%;
  justify-content: space-between;
}

.Theme_3_navbar_Buttons i {
  margin-right: 15px;
  font-size: 19px;
}

.contactus_theme_3 {
  border: 0 !important;
  position: absolute;
  right: 20px;
}

.theme3-navbar-onhover {
  width: 97%;
  height: max-content;
  background-color: white;
  position: fixed;
  z-index: 11;
  display: flex;
  // border-bottom: 1px solid rgb(219, 219, 219);
  margin-left: 1.5%;
}

.navbar-onhover-left-Panel {
  width: 21%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 60px;
}

.navbar-onhover-left-Panel-for-sub {
  width: 100%;
  display: flex;
  outline: 1px solid lightgrey;
  // flex-direction: column;
  // align-items: flex-end;
}

.kids-eyeglass-center{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.kids-box-nav-main {
  display: flex;
  gap: 50px;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* background-color: red; */
}
.kids-box1-nav-child-element {
  width: 200px; /* Adjust width as needed */
  height: 250px; /* Adjust height as needed */
  background-color: #ffffff; /* Background color of the box */
  /* border: 1px solid #ccc;  */
  border-radius: 5px; /* Border radius for rounded corners */
  padding: 20px; /* Padding inside the box */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: small;
  transition: ease-in-out 0.3s;
}

.navbar-onhover-left-Panel-for-sub h3 {
  border-bottom: 1px solid rgb(228, 228, 228);
  height: 55px;
  margin: 0;
  font-size: 14px;
  width: 90%;
  display: flex;
  align-items: center;
  padding-left: 35px;
  margin-bottom: 5px;
  color: #000042;
}

.navbar-onhover-left-Panel-child {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 195px;
  border-radius: 5px;
  margin-bottom: 5px;
  transition: all;
  transition-duration: 200ms;
  padding: 8px 20px;
  font-size: 16px;
  // height: 60px;
}

.navbar-onhover-left-Panel-child img {
  width: 50px;
}

.navbar-onhover-left-Panel-child:hover {
  background-color: rgb(255, 243, 232);
}

.navbar-onhover-right-Panel {
  display: flex;
  width: 78%;
}

.theme-3-onhover-heading-box {
  width: 77%;
}

.theme-3-onhover-heading {
  height: 55px;
  border-bottom: 1px solid rgb(228, 228, 228);
  display: flex;
  font-size: 14px;
  color: $Theme_3color;
  font-weight: 600;
}

.theme-3-onhover-heading div {
  width: 25%;
  display: flex;
  align-items: center;
  padding-left: 15px;
}

.theme-3-onhover-heading-box-2 {
  width: 77%;
}

.theme-3-onhover-heading-2 {
  display: flex;
  width: 30%;
}

.theme-3-onhover-heading-2 div {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
}

.theme-3-onhover-heading-2 P {
  margin: 0;
  padding-left: 15px;
  color: rgb(117, 117, 117);
  font-size: 13px;
  margin-top: 10px;
  cursor: pointer;
  width: 90%;
}

.theme-3-onhover-heading-2 i {
  margin-left: 20px;
  display: none;
}

.theme-3-onhover-heading-2 P:hover theme-3-onhover-heading-2 i {
  display: block;
}

.theme-3-onhover-heading-2 P:hover {
  color: $Theme_3color;
}

.Flex-SubCategory-Lc {
  display: flex;
  justify-content: space-evenly;
}

.Flex-Gender-Lc {
  display: flex;
  justify-content: flex-start;
}

.Subcategory-Men-Lc {
  padding-left: 20px;
}

.Flex-Select-Category-Lc {
  display: flex;
  justify-content: center;
  padding-left: 20px;
}

.Select-Category-width-Lc {
  width: 17%;
}

// .Flex-Select-Category-Lc {
//   display: flex;
//   height: 30px;
//   color: gray;
// }
.Font-image-nav-Lc {
  margin: 0px 10px;
}

.Subitem-Color-Lc {
  font-size: 14px;
  color: #000042;
}
.Header-Category-Color {
  color: #000042;
}

.Submenu-color-home {
  color: #000042;
}


// -----------------------------TOPNAVBAR-----------------------------------------------



.topnavbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
}

.topnavbar-left {
  padding: 8px 10px;
  display: flex;
  justify-content: space-between;
}

.head-top-left-name{
  margin-left: 5px;
}

.Head-top-contect {
  font-size: 11px !important;
  color: #000042;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px !important;
  border-right: 1px solid #000042;
  font-weight: 600;
  cursor: pointer !important;
}

.topnavbar-right {
  margin: 0px;
  width: auto;
  padding-right: 20px;
}

.goldmax-cpn-member{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.gold-membership{
  height: 29px;
  width: 75px;
  border-radius: 5px;
  margin-right: 15px;
}