/* .main-div-phone-appointment {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 12px;
}

.box-for-number-appointment {
  width: 400px;
  min-height: 500px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid rgb(226, 226, 238);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 66, 0.08) 0px 4px 16px 0px;
}

.input-section-appointment {
  width: 100%;
  min-height: 30px;
  max-height: fit-content;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.h4-enter-appointment {
  width: 100%;
  height: auto;
  font-size: 17px;
  font-family: serif;
  color: #000042;
}

.input-section-appointment {
  height: auto;
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: start;
  margin-top: 15px;
}

.label-for-phone-appointment {
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 16px;
  color: rgb(102, 102, 142);
}

.div-for-phone-number-appointment {
  width: fit-content;
  height: auto;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 10px;
  border: 1px solid rgb(226, 226, 238);
}

.p-for-country-appointment {
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  color: rgb(102, 102, 142);
}

.phone-number-input-appointment {
  outline: none;
  border: none;
  margin-left: 5px;
  -webkit-appearance: none;
  appearance: none;
}

.phone-number-input-appointment::-webkit-inner-spin-button {
  display: none;
}

.p-for-warning-appointment {
  width: auto;
  font-size: 13px;
  padding: 0px;
  margin: 0px;
  height: auto;
  padding-left: 10px;
  color: red;
}

.label-whats-up-appointment {
  width: 100%;
  font-size: 15px;
  color: #000042;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 10px;
  gap: 5px;
}

.whatsuptext-appointment {
  width: auto;
  font-size: 15px;
  color: rgb(102, 102, 142);
}

.button-div-appointment {
  box-shadow: 0 -4px 4px -4px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  width: 85%;
  height: 40px;
  padding: 30px;
}

.btn-proceed-appointment {
  cursor: pointer;
  font-size: 14px;
  width: 180px;
  height: auto;
  padding: 9px;
  background: transparent;
  background-color: rgb(230, 230, 236);
  border: none;
  border-radius: 7px;
}

.otp-border-appointment {
  border: 1px solid rgb(226, 226, 238);
  width: 190px;
  margin-left: 0px;
  border-radius: 8px;
  margin-top: 15px;
  margin-bottom: 20px;
  height: 24px;
  padding-left: 10px;
}

.green-appointment {
  color: green;
}

@media (max-width: 768px) {
  .box-for-number-appointment {
    width: 80%;
  }

  .phone-number-input-appointment,
  .otp-border-appointment {
    width: 100%;
  }

  .button-div-appointment {
    width: 50%;
    padding: 15px;
  }

  .btn-proceed-appointment {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .h4-enter-appointment {
    font-size: 16px;
  }

  .label-for-phone-appointment {
    font-size: 14px;
  }

  .btn-proceed-appointment {
    font-size: 13px;
    padding: 8px;
  }
} */

.appointment-app-container {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f5;
}

.main-div-phone-appointment {
  width: 100%;
  max-width: 500px;
  margin: 20px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-for-number-appointment {
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #e2e2ee;
  border-radius: 10px;
  box-shadow: 0px 4px 16px rgba(0, 0, 66, 0.08);
  background-color: #ffffff;
}

.h4-enter-appointment {
  width: 100%;
  font-size: 1.2em;
  color: #000042;
  text-align: center;
  margin-bottom: 20px;
}

.input-section-appointment {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.label-for-phone-appointment {
  width: 100%;
  font-size: 0.9em;
  font-weight: 400;
  margin-bottom: 10px;
  color: #66668e;
}

.div-for-phone-number-appointment {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 10px;
  border: 1px solid #e2e2ee;
  margin-bottom: 10px;
}

.phone-number-input-appointment {
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  font-size: 1em;
}

.phone-number-input-appointment::placeholder {
  color: #cccccc;
}

.p-for-warning-appointment {
  font-size: 0.85em;
  margin: 0;
  padding-left: 10px;
  color: red;
}

.green-appointment {
  color: green;
}

.otp-border-appointment {
  width: 100%;
  padding: 10px;
  border: 1px solid #e2e2ee;
  border-radius: 8px;
  margin-top: 10px;
}

.label-whats-up-appointment {
  width: 100%;
  font-size: 0.9em;
  color: #000042;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.whatsuptext {
  margin-left: 5px;
}

.button-div-appointment {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.btn-proceed-appointment {
  cursor: pointer;
  width: 180px;
  padding: 10px;
  font-size: 1em;
  background-color: #000042;
  border: none;
  border-radius: 7px;
  transition: background-color 0.3s;
}

.btn-proceed-appointment:hover {
  background-color: #d0d0d8;
}

@media (max-width: 600px) {
  .box-for-number-appointment {
    padding: 15px;
  }

  .h4-enter-appointment {
    font-size: 1em;
  }

  .phone-number-input-appointment,
  .otp-border-appointment {
    font-size: 0.9em;
    padding: 8px;
  }

  .btn-proceed-appointment {
    width: 100%;
    font-size: 0.9em;
  }
}
