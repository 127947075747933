@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dosis&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Patua+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");

$primary-font: "Plus Jakarta Sans", sans-serif; // lenscart
// $primary-font: "Montserrat", sans-serif;
$secondary-font: "Dosis", sans-serif;
$Tertiary-font: "Patua One", serif;
$font-option: "Lato", sans-serif;
$font-Theme3: "Work Sans", sans-serif;
// $Theme-color: #943409;     // pintoo red
$Theme-color: #00bac6; // for pintoo
// $Theme-color: #000064;    // lens2cart
$Theme_3color: #000042;

$Mobile-icon-color: rgb(255, 255, 255);
$sidebar-background: white;
$sidebar-font-color: rgb(0, 0, 0);

//navbar
$navbar-background-color: rgb(255, 255, 255);
$navbar-text-color: rgb(0, 0, 0);

//footer color
$footer-background: rgb(247, 247, 247);
$footer-text: black;

//Light theme variables
$background-color-light: #ffffff;
$text-color-light: #3b3b3b;
$border-light: 1px solid rgb(230, 230, 230);

//Dark theme variables
$background-color-dark: #3d3d3d;
$text-color-dark: #f8f8f8;
$border-dark: 1px solid #f8f8f8;
