.lens2kart-gold {
    width: 100%;
    height: auto;
    border: 1px solid black;
    background-color: black;
    color: #e0c67c;
    position: relative;
}

.lens2cart-gold-top {
    padding-top: 30px;
    padding-bottom: 10px;
    flex-direction: column;
    display: flex;
}


.label-lens2kart-gold {
    font-style: sans-serif;
    font-size: 35px;
    margin-bottom: 12px;
    margin-top: 30px;
    text-align: center;
}

.sublabel-lens2kart-gold {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    p {
        font-style: normal;
        font-size: 17px;
        color: white;
    }
}


.card-div {
    width: 40%;
    height: auto;
    margin: 15px;

    img {
        width: 100%;
    }
}


.fourcard {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    flex-wrap: wrap;
    margin: auto;
    position: relative;
}

.lens2kart-gold::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;

    background: url("../../Assets/GoldMembership/GoldbgLeft.png") left -80% / 100% no-repeat;
    width: 344px;
    position: absolute;
    left: 0px;
    z-index: 1;
    /* border: 1px solid white; */
    height: 100%;
}

.lens2kart-gold::after {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;

    background: url('/src/MyApp/Assets/GoldMembership/GoldbgRIght.png') left -80% / 100% no-repeat;
    width: 344px;
    position: absolute;

    z-index: 1;
    /* border: 1px solid white; */
    height: 100%;
}


.gold-max-button {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 30px;
}


.goldmax-button {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    border: 1.2px solid #e0c67c;
    border-radius: 1rem;
}


.goldmax-button-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-top: 15px; */
    padding-bottom: 15px;
    gap: 20px;


}

.goldmax-button-top-name {
    margin-right: 40px;
    font-size: 22px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    font-style: normal;
    color: rgb(224, 198, 124);
}


.goldmax-button-top-price {
    color: white;
    font-size: 24px;
    font-style: sans-serif;
    font-size: 28px;
    font-weight: 800;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: left;
    color: rgb(243, 233, 226);
}


.goldmax-button-added {
    width: 100%;
    height: auto;
    box-shadow: rgba(0, 0, 66, 0.06) 0px 0px 12px 0px;
    width: 100%;
    padding: 0px 20px;
    border-radius: 15px;
    font-family: sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 56px;
    height: 56px;
    letter-spacing: 0px;
    text-align: center;
    background: #e0c67c;
    color: rgb(0, 0, 0);
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    cursor: pointer;
}


.faq-data {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* border: 1px solid white; */
}


.faq-label {
    width: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    margin-bottom: 20px;
    font-size: 25px;
    font-family: normal;
    /* border: 1px solid white; */

    img {
        max-width: 700px;
        height: 100%;
        background-position: center;
    }
}


.ul-for-faq-data {
    width: 600px;
    /* border: 1px solid white; */

    li {
        border-bottom: 1px solid;
        min-height: 50px;
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        padding-bottom: 30px;
        padding-top: 20px;
        cursor: pointer;

        p {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-align: left;
            font-size: 20px;
            font-weight: 800;
            font-family: normal !important;
        }
    }
}


.faq-data-content {
    width: 100%;
    height: auto;

    p {

        color: #f7f1de !important;
        font-family: var(--font-plus-jakarta-sans-medium);
        font-size: 17px !important;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
        color: #f7f1de;
        padding-top: 32px;
        display: block;
    }
}


.gold-max-bottom {
    margin-top: 30px !important;
    margin-bottom: 70px !important;
}



.footer-black {
    width: 100%;
    min-height: 300px;
    border-top: 1px solid #e0c67c;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    padding-top: 30px;
}

.footer-black-top {
    width: 100%;
    height: auto;
    display: flex;
    align-items: start;
    justify-content: space-between;
    padding-left: 70px;
    padding-right: 70px;
}


.footer-black-top-left {
    width: auto;
    display: flex;
    align-items: start;
    justify-content: start;
}

.footer-black-top-left {
    width: auto;
    color: white;
    display: flex;
    align-items: start;
    justify-content: start;

    ul {
        width: 200px;
        display: flex;
        flex-direction: column;
        align-items: start;
    }

    h2 {

        font-size: 22px !important;
        line-height: 25px;
        font-size: var(--fs-20);
        font-weight: var(--fw-light);
        margin: 20px 0px 10px;
        font-family: Arial, Helvetica, sans-serif;


    }

    li {
        list-style: none;
        padding-top: 18px;
        font-style: Arial !important;
    }
}


.footer-black-top-right {
    min-width: 300px;
    /* border: 1px solid white; */
    color: white;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    min-height: 200px;
}


.footer-black-top-right-top {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-black-top-right-bottom {
    width: 100%;

    h2 {
        font-size: 14px !important;
        line-height: 1.4em;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 300;
    }
}


.hr-footer-bottom {
    width: 90%;
    background-color: white;
    height: 1px;
    margin-bottom: 25px;
    margin-top: 35px;
}


.footer-bottom {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 66px;
    padding-right: 66px;
    padding-bottom: 30px;
}

.footer-bottom-left {
    display: flex;
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
    gap: 20px;

    p {
        color: white;
        padding-right: 50px;
    }
}

.footer-bottom-top {
    width: 100%;
    display: flex;
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
    align-items: center;
    justify-content: space-between;

}


.footer-bottom-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;

    p {
        width: auto;
        padding-right: 30px;
    }
}


.footer-bottom-right-icon {
    min-width: 100px;
    /* border: 1px solid red; */
    /* padding-left: 10px; */
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: start;

    svg {
        width: 20px;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-right: 14px;
        cursor: pointer;
    }
}


.footer-bottom-span-bar {
    padding-right: 30px;
}





/* -------------mobilecss--------------- */
@media screen and (max-width:640px) {
    .lens2kart-gold::after {
        display: none;
    }

    .lens2kart-gold::before {
        display: none;
    }

    .fourcard {
        width: 98%;
    }

    .card-div {
        margin: 0;
    }

    .goldmax-button {
        width: 75%;
    }

    .goldmax-button-top {
        padding-bottom: 3px;
    }

    .goldmax-button-top-name {
        font-size: 15px;
    }

    .goldmax-button-added {
        padding: 0px;
    }

    .faq-label {
        img {
            max-width: 300px;
        }
    }

    .ul-for-faq-data {
        width: 300px;
    }

    .footer-black-top {
        flex-direction: column;
        padding-left: 20px;
        padding-right: 20px;
    }

    .footer-black-top-left {
        flex-direction: column;
    }

    .footer-bottom-top {
        width: 100%;

        flex-direction: column;
    }

    .footer-bottom-left {
        gap: 20px;

        p {
            padding: 0px;
            padding-right: 0px !important;
        }
    }

    .footer-bottom {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 15px;
        page-break-inside: 15px;
    }

    .footer-bottom-right {
        gap: 20px;

        p {
            padding-right: 0px;
            width: 100px;
        }

        span {
            padding-right: 0px;
        }
    }

}